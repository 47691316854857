<template>
  <div class="page flex-col">
    <Header></Header>
    <div class="group_8 flex-col">
      <div class="box_7 flex-row justify-between">
        <div class="box_8 flex-col justify-between">
          <Left></Left>
        </div>
        <div class="block_5 flex-col">


          <div class="order_type">
            <van-tabs v-model="orderActive" @change="handleChange">
              <van-tab v-for="(type, index) in orderTypes" :key="index" :title="type.name" :index="index">
              </van-tab>
            </van-tabs>
          </div>
          <div class="section_1 flex-col"></div>
          <div>

            <form action="/">
              <van-search
                  v-model="search"
                  placeholder="输入订单号进行搜索"
                  @search="onSearch"
              />
            </form>
          </div>

          <div class="text-wrapper_5 flex-row">
            <span class="text_23">图片</span>
            <span class="text_24">商品标题</span>
            <span class="text_25">星币价格</span>
            <span class="text_26">操作日期</span>
            <span class="text_27">操作</span>
          </div>

          <div v-if="orderList.length<1">
            <van-empty description="无数据"/>
          </div>

          <div class="section_3 flex-col" v-for="(item, index) in orderList" :key="index">

            <div class="box_1 flex-row">
              <!--              <div class="group_4 flex-col"></div>-->
              <span class="text_28">订单号：{{ item.order_no }}</span>
            </div>
            <div class="box_9 flex-row">
              <div class="image-text_15 flex-row justify-between">
                <img
                    class="image_5"
                    referrerpolicy="no-referrer"
                    :src=item.goods.image
                />
                <div class="text-group_15 flex-col justify-between">
                  <span class="paragraph_1">
                    {{ item.goods.title }}
                  </span>
                  <span class="text_29">运费：{{ item.shipping_fee }}</span>
                </div>
              </div>
              <span class="text_30">{{ item.price }}</span>
              <span class="text_31">{{ item.created_at }}</span>
              <div v-if="item.common_no !== ''" class="text-wrapper_6 flex-col">
                <span class="text_32 pointer" @click="getOrderDetail(item.common_no)">{{
                    item.show_text
                  }}</span>
              </div>
            </div>
          </div>


          <div class="box_14 flex-row" v-if="total_items>10">
            <van-pagination v-model="current_page" :total-items=total_items :items-per-page=per_page
                            :show-page-size="19"
                            @change="changePage"
                            force-ellipses>
              <template #prev-text>
                <van-icon name="arrow-left"/>
              </template>
              <template #next-text>
                <van-icon name="arrow"/>
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </div>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Left from "../../components/Left";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {myOrder} from "../../utils/api/user"
import {Toast} from "vant";


export default {
  components: {
    Left,
    Header,
    Footer,
  },
  data() {
    return {
      page: 1,
      status: null,
      search: '',
      orderActive: -1,
      current_page: 1,
      per_page: 10,
      total_items: 0,
      constants: {},
      orderTypes: [
        {
          'type_id': 0,
          'name': '所有订单',
          'status': null,
        },
        {
          'type_id': 1,
          'name': '待付款',
          'status': 0,
        },
        {
          'type_id': 2,
          'name': '待发货',
          'status': 1,
        },
        {
          'type_id': 3,
          'name': '待收货',
          'status': 2,
        },
        // {
        //   'type_id': 5,
        //   'name': '待评价',
        // },
      ],
      orderList: [
        // {
        //   "order_no": "KDBQTwVPOvbn32hvPVRT",
        //   "shipping_name": "顺丰速运",
        //   "shipping_fee": "10.00",
        //   "price": "79.00",
        //   "express_no": "SF1321421094149",
        //   "created_at": "2023-10-20 00:27:24",
        //   "goods": {
        //     "id": 2,
        //     "goods_no": "MRIUnCrRrO61LxHKpdFy",
        //     "category_id": "实物周边",
        //     "title": "绝地求生 PUBG战场生存者系列盲盒潮流手办创意礼物",
        //     "image": "http://106.15.109.145/api/public/images/goods/O1CN01QvWV6z1o8UNYikpew_!!0-item_pic.jpg_180x180.jpg",
        //     "price": "79.00",
        //     "num": 200,
        //     "content": "品牌：绝地求生（模玩）适用人群：男女通用颜色分类：单盒（随机发） 端盒（内含6盒，不重复）\n周边产品：其它实物出售状态：现货游戏名称：绝地求生\n大小：盲盒现货发售",
        //   }
        // }
      ]
    };
  },
  methods: {
    handleChange(type) {
      let that = this;
      this.orderTypes.forEach(function (item) {
        if (item.type_id === type) {
          that.status = item.status;
        }
      })
      this.page = 1;
      this.getMyOrder();
    },
    onSearch() {
      // if (this.search === '') {
      //   return false;
      // }
      this.page = 1;
      this.getMyOrder();
    },
    getOrderDetail(text) {
      navigator.clipboard.writeText(text).then(() => {
        Toast.success('单号已复制!');
      });
    },
    getMyOrder() {
      let param = {
        page: this.page,
        per_page: this.per_page,
      };
      if (this.status !== null) {
        param.status = this.status
      }
      if (this.search !== '') {
        param.order_no = this.search
      }
      console.log(param);
      myOrder(param).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          let orderList = res.data.data.data;
          orderList.forEach(function (item) {
            // console.log(item.goods.is_virtual)
            if (item.goods.is_virtual === 0) {
              item.show_text = '复制运单号';
              item.common_no = item.express_no;
            } else {
              item.show_text = '复制CDK';
              item.common_no = item.cdk;
            }
          })
          this.orderList = orderList;
          this.per_page = res.data.data.per_page
          this.page = res.data.data.page
          this.total_items = res.data.data.total
        }
      })
    },
    changePage(page) {
      this.page = page;
      this.getMyOrder();
    }
  },
  mounted() {
    this.getMyOrder();
  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>