<template>
  <div class="header flex-col">
    <div class="header_box flex-row">
      <router-link target="_blank" to="/" class="icon">
        <img
            class="image_icon"
            referrerpolicy="no-referrer"
            src="./images/icon.png"
        />
      </router-link>


      <div class="module">
        <span class="module_item pointer" v-for="(module, index) in modules" :key="index"
              :class="{active:module.id === activeTab}" @click="setActiveTab(module.url)">
          {{ module.name }}
        </span>
      </div>

      <div class="user">

        <span class="" @mouseover="onMouseOver" @mouseleave="onMouseOut">
          <img
              class="user_headimg"
              referrerpolicy="no-referrer"
              :src=user_info.avatar
          />
          <span class="user_name">
              {{ user_info.nickname }}
          </span>

          <div class="setting" v-if="showInfo">
              <div class="setting_img">
                <img class="setting_avatar" :src=user_info.avatar>
              </div>
              <div class="setting_name">{{ user_info.nickname }}</div>

              <div class="setting_divide"></div>

              <div class="setting_item pointer">
                <router-link target="_blank" to="/user/my_data"><van-icon name="https://b.yzcdn.cn/vant/icon-demo-1126.png"/>&ensp;个人中心 </router-link>
              </div>
              <div class="setting_item pointer" @click="logout">
                <van-icon name="exchange"/> &ensp;退出登录
              </div>
          </div>


        </span>


        <!--        <router-link target="_blank" to="/user/info">-->
        <!--          <img-->
        <!--              class="other_img"-->
        <!--              referrerpolicy="no-referrer"-->
        <!--              src="./images/image1.png"-->
        <!--          />-->
        <!--        </router-link>-->
        <span v-if="user_info.display_icon">
          <img
              class="other_img"
              referrerpolicy="no-referrer"
              src="./images/image2.png"
          />
        </span>


      </div>


    </div>
  </div>
</template>

<script>
import {getUserInfo, setUserInfo, setToken, getToken} from "../utils/token"
import {logOut} from "../utils/api/user"
import {Dialog} from "vant";

export default {
  name: 'Header',
  data() {
    return {
      activeTab: 0,
      showInfo: false,
      user_info: {
        avatar: '',
        fullname: '',
        display_icon: '',
      },
      modules: [
        {
          "id": 1,
          "name": "首页",
          "url": "/index",
          "module": "index",
        },
        {
          "id": 2,
          "name": "活动",
          "url": "/activity/list",
          "module": "activity",
        },
        {
          "id": 3,
          "name": "资讯",
          "url": "/content/list",
          "module": "content",
        },
        {
          "id": 4,
          "name": "排行榜",
          "url": "/rank/list",
          "module": "rank",
        },
        {
          "id": 5,
          "name": "商品",
          "url": "/goods/list",
          "module": "goods",
        }
      ],
    };
  },
  props: {},
  methods: {
    onMouseOver() {
      this.showInfo = true;
    },
    onMouseOut() {
      this.showInfo = false;
    },
    setActiveTab(url) {
      if (this.$router.currentRoute.path !== url) {
        let routeData = this.$router.resolve({ path: url });
        window.open(routeData.href, '_blank');
        // this.$router.push(url)
      }
    },
    logout() {
      Dialog.confirm({
        title: '退出登录',
        message: '您确认退出登录吗？',
      })
          .then(() => {
            // on confirm
            logOut().then((res) => {
              if (res.data.code === 200) {
                setUserInfo('');
                setToken('');
                this.$router.push('/user/login')
              }
            })
          })
          .catch(() => {
            // on cancel
          });
    }
  },
  mounted() {
    let that = this
    this.modules.forEach(function (item) {
      if (that.$router.currentRoute.path.startsWith('/' + item.module)) {
        that.activeTab = item.id;
      }
    })

    let user_info = getUserInfo();
    let token = getToken();
    if (!user_info || !token) {
      setToken('');
      setUserInfo('');
      this.$router.push('/user/login');
    } else {
      this.user_info = user_info;
    }

  },

}
</script>

<style scoped lang="css" src="./css/header.css"/>