<template>
  <div class="page flex-col">
    <Header></Header>
    <div class="group_7 flex-col">
      <div class="block_22 flex-row justify-between">
        <Left></Left>
        <div class="section_2 flex-col">
          <div class="section_3 flex-row justify-between">
            <div class="group_5 flex-col"></div>
            <span class="text_16">基本资料</span>
          </div>
          <div class="text-wrapper_28 flex-row"><span class="text_17">基础信息</span></div>
          <div class="form_1">
            <van-form @submit="onSubmit1" input-align="left">
              <van-field
                  class="not-allowed"
                  readonly
                  v-model="form_1.nickname"
                  name="账号"
                  label="账号"
                  placeholder="账号"
                  :rules="[{ required: true, message: '账号必填' }]"
              />
              <van-field
                  v-model="form_1.signature"
                  name="签名"
                  label="签名"
                  placeholder="输入20个字以内个性签字"
              />


              <van-field
                  class="not-allowed"
                  readonly
                  label="生日"
                  v-model="form_1.birthday"
                  placeholder="点击选择时间"
                  :min-date="minDate"
                  :max-date="maxDate"
                  type="date"
                  @click="onSelectTime"
              />

              <div class="user_gender flex-row">
                <div class="flex-col">性别</div>
                <div class="gender flex-col not-allowed">
                  <van-radio-group v-model="form_1.gender" readonly direction="horizontal">
                    <van-radio disabled name="0">未知</van-radio>
                    <van-radio disabled name="1">男性</van-radio>
                    <van-radio disabled name="2">女性</van-radio>
                  </van-radio-group>
                </div>
              </div>
              <!--              </van-field>-->

              <van-field
                  class="not-allowed"
                  readonly
                  clickable
                  name="picker"
                  :value="form_1.city"
                  label="城市"
                  placeholder="点击选择城市"
                  @click="showPicker = false"
              />
              <van-popup v-model="showPicker" position="bottom" disable-scroll>
                <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onCityConfirm"
                    @cancel="showPicker = false"
                />
              </van-popup>

              <div class="user_avatar flex-row">
                <div class="avatar_text flex-col">头像</div>
                <div class="flex-col">
                  <van-uploader
                      class="avatar"
                      v-model="fileList"
                      :max-count="1"
                      :after-read="afterRead"
                      accept=".png,.jpg,.jpeg"
                  >
                  </van-uploader>
                </div>
              </div>


              <van-field
                  class="not-allowed"
                  readonly
                  v-model="form_1.wechat"
                  name="微信"
                  label="微信"
                  placeholder="请输入微信账号"
              />

              <van-field
                  class="not-allowed"
                  readonly
                  v-model="form_1.qq"
                  name="QQ"
                  label="QQ"
                  placeholder="请输入QQ号"
              />
              <van-field
                  class="not-allowed"
                  readonly
                  v-model="form_1.phone"
                  name="手机号"
                  label="手机号"
                  placeholder="请输入手机号"
              />
              <van-field
                  class="not-allowed"
                  readonly
                  v-model="form_1.steam"
                  name="STEAM"
                  label="STEAM"
                  placeholder="请输入STEAM17位数字"
              />

              <van-field
                  class="not-allowed"
                  readonly
                  v-model="form_1.official_server_id"
                  name="正式服ID"
                  label="正式服ID"
                  placeholder="PUBG ID"
              />
              <van-checkbox disabled class="has_auth" v-model="form_1.has_partner_privilege_official" shape="square">
                拥有合作伙伴权限
              </van-checkbox>

              <van-field
                  class="not-allowed"
                  readonly
                  v-model="form_1.test_server_id"
                  name="测试服ID"
                  label="测试服ID"
                  placeholder="PUBG ID"
              />
              <van-checkbox disabled class="has_auth" v-model="form_1.has_partner_privilege_test" shape="square">
                拥有合作伙伴权限
              </van-checkbox>

              <van-button native-type="submit" class="submit_1">提交</van-button>
            </van-form>
          </div>


          <!--          <div class="text-wrapper_30 flex-row"><span class="text_43">*请务必仔细填写自己的平台信息</span></div>-->
          <div class="text-wrapper_28 flex-row"><span class="text_17">平台信息</span></div>

          <div class="section_16" v-if="lists.length<1">
            <van-empty description="暂无平台信息"/>
          </div>


          <div class="section_16 flex-row justify-between">

            <van-form @submit="onSubmit2" input-align="left" class="form_1">

              <div v-for="(list,index) in lists" :key="index" class="platform">
                <van-field
                    class="not-allowed"
                    readonly
                    clickable
                    name="picker"
                    :value="list.platform.name"
                    label="平台名称"
                    placeholder="暂无"
                />

                <van-field
                    class="not-allowed"
                    readonly
                    v-model="list.platform_nickname"
                    name="账号昵称"
                    label="账号昵称"
                    placeholder="暂无"
                />

                <van-field
                    class="not-allowed"
                    readonly
                    v-model="list.homepage_link"
                    name="主页链接"
                    label="主页链接"
                    placeholder="暂无"
                />
              </div>
              <!--              <van-button native-type="submit" class="submit_1">提交</van-button>-->
            </van-form>

          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Left from "../../components/Left";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Toast} from "vant";
import {getUserPlatInfo, updateUserInfo} from "../../utils/api/user"
import {uploadFile} from "../../utils/api/upload";

export default {
  components: {
    Left,
    Header,
    Footer,
  },
  data() {
    return {
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      fileList: [],
      form_1: {
        avatar: '',
        birthday: '2022-10-12',
        nickname: '',
        gender: '',
        qq: '',
        wechat: '',
        phone: '',
        city: '',
        signature: '',
        steam: '',
        official_server_id: '',
        test_server_id: '',
        has_partner_privilege_official: false,
        has_partner_privilege_test: false,
      },
      lists: [],
      form_2: {
        platform_1: '',
        platform_nickname_1: '',
        homepage_link_1: '',
        platform_2: '',
        platform_nickname_2: '',
        homepage_link_2: ''
      },
      columns: ['北京市', '天津市', '重庆市', '上海市', '河北省', '山西省',
        '辽宁省', '吉林省', '黑龙江省', '江苏省', '浙江省', '安徽省', '福建省',
        '江西省', '山东省', '河南省', '湖北省', '湖南省', '广东省', '海南省',
        '四川省', '贵州省', '云南省', '陕西省', '甘肃省', '青海省', '台湾省',
        '内蒙古自治区', '广西壮族自治区', '西藏自治区', '宁夏回族自治区',
        '新疆维吾尔自治区', '香港特别行政区', '澳门特别行政区'
      ],
      showPicker: false,
      platforms: ['抖音', '微信', '百度', '网易', 'B站'],
      showMainPlatformPicker: false,
      showOtherPlatformPicker: false,
    };
  },
  methods: {
    onMainPlatformConfirm(value) {
      this.form_2.platform_1 = value;
      this.showMainPlatformPicker = false;
    },
    onOtherPlatformConfirm(value) {
      this.form_2.platform_2 = value;
      this.showOtherPlatformPicker = false;
    },
    onSubmit1() {
      // this.form_1.has_partner_privilege_official = Number(this.form_1.has_partner_privilege_official);
      // this.form_1.has_partner_privilege_test = Number(this.form_1.has_partner_privilege_test);
      const formData = new FormData();
      formData.append('signature', this.form_1.signature);
      formData.append('avatar', this.form_1.avatar);
      updateUserInfo(formData).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          Toast.success('更新成功！')
          console.log(this.form_1);

          // setUserInfo(this.form_1);
          // this.$router.push('/index')
        }
      })
    },
    afterRead() {
      let that = this;
      uploadFile(this.fileList[0].file).then((res) => {
        that.form_1.avatar = res;
      });
    },
    onSubmit2() {
      // updatePlatformInfo(this.form_2).then((res) => {
      //   console.log(res);
      //   if (res.data.code === 200) {
      //     Toast.success('更新成功！')
      //   }
      // })
    },
    onConfirm() {
      // console.log(123);
    },
    onCancel() {
      // console.log(123);
    },
    onChange() {
      // console.log(123);
    },
    onSelectTime() {
      // console.log(time)
    },
    onCityConfirm(value) {
      this.form_1.city = value;
      this.showPicker = false;
    },
    getUserInfo() {
      getUserPlatInfo().then((res) => {
        let userInfo = res.data.data.user;
        this.fileList[0] = {url: userInfo.avatar, isImage: true}
        this.form_1.avatar = userInfo.avatar;
        this.form_1.gender = userInfo.gender + '';
        this.form_1.birthday = userInfo.birthday;
        this.form_1.city = userInfo.city;
        this.form_1.city_id = userInfo.city_id;
        this.form_1.nickname = userInfo.nickname;
        this.form_1.official_server_id = userInfo.official_server_id;
        this.form_1.phone = userInfo.phone;
        this.form_1.province_id = userInfo.province_id;
        this.form_1.qq = userInfo.qq;
        this.form_1.wechat = userInfo.wechat;
        this.form_1.signature = userInfo.signature;
        this.form_1.steam = userInfo.steam;
        this.form_1.test_server_id = userInfo.test_server_id;
        this.form_1.has_partner_privilege_official = Boolean(userInfo.has_partner_privilege_official);
        this.form_1.has_partner_privilege_test = Boolean(userInfo.has_partner_privilege_test);


        this.lists =  res.data.data.user_platform;

        console.log(res);
      })
      // Toast.fail('登录失败');
    },
  },
  mounted() {
    this.getUserInfo();

  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>