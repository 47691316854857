import request from "../request";

// 兑换商品
export function exchangeGoods(data) {
    return request({
        method: 'post',
        url: '/goods/goods_exchange',
        data: data
    })
}

// 商品详情
export function goodsDetail(params = {}) {
    return request({
        method: 'get',
        url: '/goods/detail',
        params
    })
}

// 商品列表
export function goodsList(params = {}) {
    return request({
        method: 'get',
        url: '/goods/list',
        params
    })
}


export function goodsCategory(params = {}) {
    return request({
        method: 'get',
        url: '/goods/get_category',
        params
    })
}
