<template>
  <div class="page flex-col">
    <Header></Header>

    <div class="box_2 flex-col">
      <div class="section_1 flex-row ">
        <img
            class="image_3"
            referrerpolicy="no-referrer"
            :src=goods.image
        />
        <div class="box_3 flex-col">
          <span class="text_7">
            {{ goods.title }}
          </span>
          <div class="text-wrapper_1 flex-row">
            <span class="text_8">星币价格：</span>
            <span class="text_9">{{ goods.price }}</span>
          </div>
          <div class="text-wrapper_2 flex-row justify-between">
            <span class="text_10">商品编号：</span>
            <span class="text_11">{{ goods.goods_no }}</span>
          </div>
          <div class="text-wrapper_3 flex-row justify-between">
            <span class="text_12">商品类型：</span>
            <span class="text_13">{{ goods.goods_category.name }}</span>
          </div>


          <van-field name="stepper" label="商品数量:" max="99" integer @change="onChange">

            <template #input>
              <van-stepper v-model="goods_num"/>
            </template>

          </van-field>


          <!--          <div class="group_1 flex-row">-->
          <!--            <span class="text_14">商品数量：</span>-->
          <!--            <span class="text_15">-</span>-->
          <!--            <div class="text-wrapper_4 flex-col">-->

          <!--            </div>-->
          <!--            <span class="text_17">+</span>-->
          <!--            <span class="text_18">（库存剩余：{{ goods.num }}）</span>-->
          <!--          </div>-->

          <div class="group_2 flex-row justify-between">
            <div v-if="enough_money" class="text-wrapper_5 flex-col">
              <van-button class="" @click="toExchangeGoods">立即兑换</van-button>
            </div>
            <div v-if="!enough_money" class="text-wrapper_6 flex-col"><span class="text_20">星币不足</span></div>
          </div>
          <span class="text_21">（星币余额：{{ goods.star_currency }}）</span>
        </div>
      </div>
      <div class="section_2 flex-col">
        <span class="text_22">商品详情</span>
        <span class="paragraph_1" v-html="goods.content">
<!--                      {{ goods.content }}-->
        </span>
        <img
            class="image_4"
            referrerpolicy="no-referrer"
            :src=goods.image
        />
      </div>
    </div>
    <Footer></Footer>

  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import {goodsDetail, exchangeGoods} from "../../utils/api/goods"
import {Toast} from "vant";


export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      enough_money: false,
      goods_id: 0,
      goods_num: 1,
      constants: {},
      goods: {}
    };
  },
  methods: {
    getGoodsInfo() {
      let param = {
        id: this.goods_id
      }
      goodsDetail(param).then((res) => {
        this.goods = res.data.data;
        this.judgeMoney();
      })
      // return true;
    },
    toExchangeGoods() {
      let param = {
        id: this.goods_id,
        num: this.goods_num
      }
      exchangeGoods(param).then((res) => {
        if (res.data.code === 200) {
          Toast.success('兑换成功！');
          this.$router.push('/user/order');
        }else{
          Toast.fail(res.data.msg);
        }
      })
    },
    onChange() {
      this.judgeMoney();
    },
    judgeMoney() {
      let goods_num = parseInt(this.goods_num);
      let single_price = parseInt(this.goods.price);
      let money = parseInt(this.goods.star_currency);
      if (money >= goods_num * single_price) {
        this.enough_money = true;
      }
    }
  },
  mounted() {
    this.goods_id = this.$router.currentRoute.query.id;
    if (this.goods_id === undefined || this.goods_id < 1) {
      Toast.fail('请选择对应商品');
      this.$router.push('/goods/list');
      return;
    }
    this.getGoodsInfo();
  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>