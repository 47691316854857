<template>
  <div class="page flex-col ">
    <Header></Header>
    <div class="box_12 flex-col">
      <div class="box_13 flex-row justify-between">

        <Left></Left>

        <div class="group_6 flex-col">
          <div class="section_14 flex-row justify-between">
            <div class="section_2 flex-col"></div>
            <span class="text_16">尊敬的PUBG&nbsp;{{ level }}级创作者</span>
          </div>
          <div class="section_15 flex-row justify-between">
            <div class="box_3 flex-col">
              <span class="text_19">{{ user_data.rank }}</span>
              <div class="group_14 flex-row justify-between">
                <span class="text_18">{{ month }}月排名</span>
                <img
                    v-if="user_data.rank_trend !== 0"
                    class="image_5"
                    referrerpolicy="no-referrer"
                    :src=user_data.rank_image
                />
              </div>
            </div>
            <div class="box_4 flex-col">
              <span class="text_19">{{ user_data.points }}</span>
              <div class="group_15 flex-row justify-between">
                <span class="text_20">{{ month }}月积分</span>
                <img
                    v-if="user_data.points_trend !== 0"
                    class="image_6"
                    referrerpolicy="no-referrer"
                    :src=getImage(user_data.points_trend)
                />
              </div>
            </div>
            <div class="box_6 flex-col">
              <span class="text_19">{{ user_data.fans }}</span>
              <div class="group_16 flex-row justify-between">
                <span class="text_22">{{ month }}月总粉丝</span>
                <img
                    v-if="user_data.fans_trend !== 0"
                    class="image_7"
                    referrerpolicy="no-referrer"
                    :src=getImage(user_data.fans_trend)
                />
              </div>
            </div>
            <div class="box_8 flex-col">
              <span class="text_19">{{ user_data.volume }}</span>
              <div class="group_17 flex-row justify-between">
                <span class="text_24">{{ month }}月播放量</span>
                <img
                    v-if="user_data.volume_trend !== 0"
                    class="image_8"
                    referrerpolicy="no-referrer"
                    :src=getImage(user_data.volume_trend)
                />
              </div>
            </div>
          </div>

          <div class="section_16 flex-row justify-between">
            <div class="section_5 flex-col"></div>
            <div class="image-text_3 flex-row justify-between">
              <span class="text-group_1">{{ level }}级创作者积分获取规则</span>
              <img
                  @mouseover="onMouseOver"
                  @mouseleave="onMouseOut"
                  class="label_6 pointer"
                  referrerpolicy="no-referrer"
                  src="./assets/img/notice.png"
              />
            </div>
          </div>

          <div class="section_6 flex-col">
            <div class="text-wrapper_28 flex-row justify-between">
              <span class="text_25">基础分</span>
              <span class="text_26">+{{ rule.jcjf }}</span>
            </div>
            <div class="text-wrapper_29 flex-row"><span class="text_27">每月发布PUBG内容>16条</span></div>
          </div>

          <div class="section_17 flex-row justify-between">

            <div class="box_9 flex-col">
              <div class="text-wrapper_30 flex-row justify-between">
                <span class="text_28">爆视频10W+/条</span>
                <span class="text_29">+{{ rule.bfl10w }}</span>
              </div>
              <div class="text-wrapper_31 flex-row"><span class="text_30">只能计算两条</span></div>
            </div>
            <div class="box_9 flex-col">
              <div class="text-wrapper_32 flex-row justify-between">
                <span class="text_31">爆视频30W+/条</span>
                <span class="text_32">+{{ rule.bfl30w }}</span>
              </div>
              <div class="text-wrapper_33 flex-row"><span class="text_33">只能计算两条</span></div>
            </div>
            <div class="box_9 flex-col">
              <div class="text-wrapper_34 flex-row justify-between">
                <span class="text_34">爆视频50W+/条</span>
                <span class="text_35">+{{ rule.bfl50w }}</span>
              </div>
              <div class="text-wrapper_35 flex-row"><span class="text_36">只能计算两条</span></div>
            </div>
          </div>

          <div class="section_17 flex-row justify-between">
            <div class="box_9 flex-col">
              <div class="text-wrapper_30 flex-row justify-between">
                <span class="text_28">爆视频80W+/条</span>
                <span class="text_29">+{{ rule.bfl80w }}</span>
              </div>
              <div class="text-wrapper_31 flex-row"><span class="text_30">无限制</span></div>
            </div>
            <div class="box_9 flex-col">
              <div class="text-wrapper_32 flex-row justify-between">
                <span class="text_31">爆视频100W+/条</span>
                <span class="text_32">+{{ rule.bfl100w }}</span>
              </div>
              <div class="text-wrapper_33 flex-row"><span class="text_33">无限制</span></div>
            </div>
            <div class="box_9 flex-col">
              <div class="text-wrapper_34 flex-row justify-between">
                <span class="text_34">爆视频120W+/条</span>
                <span class="text_35">+{{ rule.bfl120w }}</span>
              </div>
              <div class="text-wrapper_35 flex-row"><span class="text_36">无限制</span></div>
            </div>
          </div>



          <div class="section_18 flex-row justify-between">
            <div class="block_1 flex-col">
              <div class="text-wrapper_38 flex-row justify-between">
                <span class="text_40">平台排名分</span>
                <span class="text_41"></span>
              </div>
              <div class="section_19 flex-row">
                <div class="text-wrapper_15">
                  <span class="text_42">查看</span>
                  <a href="https://docs.qq.com/sheet/DTkpkTHBXc1VSYVFs?tab=BB08J2" target="_blank" class="text_43">《各平台排名分数规则》</a>
                </div>
              </div>
            </div>
            <div class="block_1 flex-col">
              <div class="text-wrapper_38 flex-row justify-between">
                <span class="text_40">创作者季度激励计划规则</span>
                <span class="text_41"></span>
              </div>
              <div class="section_19 flex-row">
                <div class="text-wrapper_15">
                  <span class="text_42">查看</span>
                  <a href="https://docs.qq.com/sheet/DTWVOTmN6aEJmUWd4?tab=BB08J2" target="_blank" class="text_43">《详细规则》</a>
                </div>
              </div>
            </div>
          </div>

          <div class="section_20 flex-row justify-between">
            <div class="group_10 flex-col">
              <div class="text-wrapper_40 flex-row justify-between">
                <span class="text_48">参加官方水友赛</span>
                <span class="text_49">+{{ rule.cjgfsys }}</span>
              </div>
              <div class="text-wrapper_41 flex-row"><span class="text_50">参加官方举办的水友赛/次</span></div>
            </div>
            <div class="group_11 flex-col">
              <div class="text-wrapper_42 flex-row justify-between">
                <span class="text_51">参加话题征稿投稿</span>
                <span class="text_52">+{{ rule.cjhttg }}</span>
              </div>
              <div class="text-wrapper_43 flex-row"><span class="text_53">参加话题征稿投稿>10篇</span></div>
            </div>
            <div class="group_12 flex-col">
              <div class="text-wrapper_44 flex-row">
                <span class="text_54">发布视频或文章</span>
                <span class="text_55">+{{ rule.fbsphwz }}</span>
              </div>
              <div class="text-wrapper_45 flex-row"><span class="text_56">视频或文章≥20篇，视频>5条</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Left from "../../components/Left";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Toast} from "vant";
import {getUserData, getPointRule} from "../../utils/api/user"


export default {
  components: {
    Left,
    Header,
    Footer
  },
  data() {
    return {
      constants: {},
      level: 'S',
      month: "9",
      user_data: {
        fans: 1,
        fans_trend: 1,
        points: null,
        points_trend: 0,
        rank: null,
        rank_trend: 0,
        volume: 1,
        volume_trend: 1,
      },
      rule: {},
    };
  },
  methods: {
    getImage(value) {
      switch (value) {
        case 0:
          return '';
        case 1:
          return require('./assets/img/' + 'up.png')
        case 2:
          return require('./assets/img/' + 'down.png')
      }
    },
    formatData(value) {
      if (value === null) {
        return '-';
      }
      return value;
    },
    onMouseOver() {
      Toast('获取更多积分方法如下')
    },
    onMouseOut() {

    },
    getUserInfo() {
      getUserData().then((res) => {
        if (res.data.code === 200) {
          const userInfo = res.data.data;
          this.user_data.fans = this.formatData(userInfo.fans);
          this.user_data.fans_trend = userInfo.fans_trend;
          this.user_data.points = this.formatData(userInfo.points);
          this.user_data.points_trend = userInfo.points_trend;
          this.user_data.rank = this.formatData(userInfo.rank);
          this.user_data.rank_trend = userInfo.rank_trend;
          this.user_data.volume = this.formatData(userInfo.volume);
          this.user_data.volume_trend = userInfo.volume_trend;
          this.month = userInfo.month;
          this.level = userInfo.level;
        }
      })
    },
    getRule() {
      getPointRule().then((res) => {
        if (res.data.code === 200) {
          this.rule = res.data.data;
        }
      })
    }
  },
  mounted() {
    this.getUserInfo()
    this.getRule()
  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>