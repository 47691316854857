<template>
  <div class="page flex-col">
    <Header></Header>
    <div class="box_7 flex-col">
      <span class="text_7">{{ news.title }}</span>
      <div class="text-wrapper_3 flex-row justify-between">
        <span class="text_8">发布人：PUBG管理员</span>
        <span class="text_9">发布时间：{{ news.created_at }}</span>
      </div>
      <div class="box_5 flex-col"></div>
      <div class="text-group_3">
        <div class="paragraph_1" v-html="news.content">
        </div>
      </div>


    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {newsDetail} from "../../utils/api/news"
import {Toast} from "vant";

export default {
  components: {Header, Footer},
  data() {
    return {
      id: 0,
      constants: {},
      news: {}
    };
  },
  methods: {
    getContent() {
      let param = {
        id: this.id,
      };
      newsDetail(param).then((res) => {
        if (res.data.code === 200) {
          this.news = res.data.data
          console.log(this.news)
        }
      })
    },
  },
  mounted() {
    this.id = this.$router.currentRoute.query.id;
    if (this.id === undefined || this.id < 1) {
      Toast.fail('请选择对应资讯');
      this.$router.push('/index');
      return;
    }
    this.getContent();
  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>