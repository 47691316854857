import Vue from 'vue'
import VueRouter from 'vue-router'
import goodsDetail from '../views/goods_detail/index.vue'
import contentDetail from '../views/content_detail/index.vue'
import login from '../views/login/index.vue'
import workOrder from '../views/work_order/index.vue'
import rank from '../views/rank/index.vue'
import index from '../views/index/index.vue'
import register from '../views/register/index.vue'
import resetPassword from '../views/reset_password/index.vue'
import info from '../views/info/index.vue'
import submit from '../views/submit/index.vue'
import order from '../views/order/index.vue'
import wallet from '../views/wallet/index.vue'
import myData from '../views/my_data/index.vue'
import activityDetail from '../views/activity_detail/index.vue'
// import activityCenter from '../views/activity_center/index.vue'
import goods from '../views/goods/index.vue'
import activity from '../views/activity/index.vue'
import content from '../views/content/index.vue'
import annualGala from '../views/annual_gala/index.vue'
// import alert from '../views/alert/index.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/index"
    },
    {
        path: '/annual_gala',
        name: 'gala',
        component: annualGala,
        meta:{
            title:'PUBG创作者中心-年度盛典'
        }
    },
    {
        path: '/goods/list',
        name: 'goods',
        component: goods,
        meta:{
            title:'PUBG创作者中心-商品列表'
        }
    },
    {
        path: '/content/list',
        name: 'content',
        component: content,
        meta:{
            title:'PUBG创作者中心-最新资讯'
        }
    },
    {
        path: '/goods/detail',
        name: 'goodsDetail',
        component: goodsDetail,
        meta:{
            title:'PUBG创作者中心-商品详情'
        }
    },
    {
        path: '/user/login',
        name: 'login',
        component: login,
        meta:{
            title:'登录-PUBG创作者中心'
        }
    },
    {
        path: '/user/work_order',
        name: 'workOrder',
        component: workOrder,
        meta:{
            title:'提交工单-PUBG创作者中心'
        }
    },
    {
        path: '/rank/list',
        name: 'rank',
        component: rank,
        meta:{
            title:'PUBG创作者中心-榜单'
        }
    },
    {
        path: '/index',
        name: 'index',
        component: index,
        meta:{
            title:'PUBG创作者中心'
        }
    },
    {
        path: '/user/register',
        name: 'register',
        component: register
    },
    {
        path: '/user/reset_password',
        name: 'resetPassword',
        component: resetPassword
    },
    {
        path: '/user/info',
        name: 'info',
        component: info,
        meta:{
            title:'PUBG创作者中心-个人中心'
        }
    },
    {
        path: '/user/submit',
        name: 'submit',
        component: submit,
        meta:{
            title:'PUBG创作者中心'
        }
    },
    {
        path: '/user/order',
        name: 'order',
        component: order,
        meta:{
            title:'我的订单-PUBG创作者中心'
        }
    },
    {
        path: '/user/wallet',
        name: 'wallet',
        component: wallet,
        meta:{
            title:'我的钱包-PUBG创作者中心'
        }
    },
    {
        path: '/user/my_data',
        name: 'myData',
        component: myData,
        meta:{
            title:'我的数据-PUBG创作者中心'
        }
    },
    // {
    //     path: '/activity/list',
    //     name: 'activity',
    //     component: activity
    // },
    {
        path: '/activity/detail',
        name: 'activityDetail',
        component: activityDetail,
        meta:{
            title:'PUBG创作者中心'
        }
    },
    {
        path: '/activity/list',
        name: 'activity',
        component: activity,
        meta:{
            title:'PUBG创作者中心-活动中心'
        }
    },
    {
        path: '/content/detail',
        name: 'contentDetail',
        component: contentDetail,
        meta:{
            title:'PUBG创作者中心'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.afterEach(() => {
    window.scrollTo(0, 0);
})

export default router