<template>
  <!--  <div class="page flex-col justify-end">-->
  <div class="page flex-col">


    <!--          这里是header-->
    <Header></Header>

    <div class="main">
      <div class="banner flex-col pointer" @click="jumpTo(background.url)"
           :style="{ backgroundImage: `url(${background.image})` }">
      </div>
      <!--    公告-->
      <van-row class="notice flex-col">
        <van-col>
          <van-swipe class="my-swipe" :autoplay="3000">
            <van-swipe-item v-for="(banner,index) in bannerList" :key="index">
              <a :href=banner.url target="_blank">
                <img class="notice_image"
                     referrerpolicy="no-referrer"
                     :src=banner.image
                />
              </a>
            </van-swipe-item>

          </van-swipe>
        </van-col>

        <van-col class="notice_list flex-col">
          <div class="notice_list_main flex-row justify-between">
            <img class="notice_list_main_img" src="./assets/img/notice_icon.png">
          </div>

          <div class="notice_list_info flex-row">
            <router-link target="_blank" :to="'content/detail?id='+noticesTop.id" class="notice_list_info_title_top">
              {{ noticesTop.title }}
            </router-link>
          </div>

          <!--          <div class="notice_list_second flex-row justify-between">-->
          <!--            <img class="notice_list_second_img" src="./assets/img/notice_title.png">-->
          <!--          </div>-->
          <div v-if="notices.length<1">
            <van-empty description="暂无公告"/>
          </div>

<!--          <div class="wrap">-->
<!--            <vue-seamless-scroll :data="notices" :step="0.5">-->
              <div v-for="(item, index) in notices" :key="index" class="notice_list_info flex-row">
                <span class="notice_list_info_top">[{{ item.news_category.name }}]</span>
                <router-link target="_blank" :to="'content/detail?id='+item.id" class="notice_list_info_title">
                  {{ item.title }}
                </router-link>
                <span class="notice_list_info_date">{{ item.date }}</span>
              </div>
<!--            </vue-seamless-scroll>-->
<!--          </div>-->
        </van-col>
      </van-row>

      <div>
        <van-row class="activity_title">
          <span class="activity_title_text">热门活动</span>
          <router-link target="_blank" class="activity_more_text" to="/activity/list">查看更多 +</router-link>
        </van-row>

        <van-row class="activity">
          <div v-if="activities.length<1">
            <van-empty description="暂无活动"/>
          </div>
          <van-col span="6" v-for="(item, index) in activities" :key="index" class="activity_item_box">
            <router-link target="_blank" :to="'/activity/detail?id='+ item.id">
              <div class="activity_item">
                <div>
                  <img class="activity_image" :src=item.image>
                </div>
                <div class="activity_desc">
                  {{ item.title }}
                </div>

                <div class="flex-row">
                  <div class="flex-col activity_status" :style="{color:item.color}">{{ item.status }}</div>
                  <div v-if="item.is_new===1" class="flex-col activity_is_new">new</div>
                </div>

              </div>
            </router-link>

          </van-col>

        </van-row>
      </div>

      <!--    排行榜-->

      <van-row class="rank_and_goods">
        <van-col class="rank flex-col">
          <van-row>
            <div class="rank_top flex-row">
              <van-col class="rank_top_box"></van-col>
              <van-col class="rank_top_title">{{ month }}积分排行榜</van-col>
            </div>
          </van-row>
          <div v-if="ranks.length<1">
            <van-empty description="暂无排行榜"/>
          </div>
          <van-row class="rank_item" v-for="(rank, index) in ranks" :key="index">
            <van-col class="rank_item_name_box">
              <img :src="rank.rank_icon" class="rank_item_name">
            </van-col>
            <van-col>
              <img class="rank_item_image" :src=rank.user.avatar>
            </van-col>

            <van-col class="rank_user">
              <van-col class="rank_item_user">{{ rank.user.nickname.substring(0, 8) }}</van-col>
              <van-col class="rank_item_user_icon_box">
                <img class="rank_item_user_icon" :src="rank.level_icon">
              </van-col>
            </van-col>


            <van-col class="rank_item_score">{{ rank.points }}</van-col>
          </van-row>

          <van-row>
            <div class="rank_see_all" v-if="ranks.length>0">
              <router-link target="_blank" to="/rank/list" class="rank_see_all_text">查看全部排行榜</router-link>
            </div>
          </van-row>

        </van-col>
        <!--        热门商品-->
        <van-col class="goods flex-col">


          <div class="goods_top flex-row">
            <div class="flex-col">
              <van-tabs v-model="goodsActive" @change="handleChange">
                <van-tab v-for="(tab, index) in goodsCate" :key="index" :title="tab.name" :index="index">
                  <div class="goods_line"></div>

                  <van-row>
                    <div v-if="goods.length<1">
                      <van-empty v-if="goods.length === 0" description="暂无商品"/>
                    </div>
                    <van-col v-for="(item, index) in goods" :key="index" class="list-item">
                      <div class="goods_info_list flex-col">
                        <div class="flex-col">
                          <!--                      <img :src="item.goods_image">-->
                          <img class="goods_info_list_img pointer" :src=item.image @click="toBuy(item.id)">
                        </div>
                        <div class="goods_info_list_title">
                          <span>{{ item.title }}</span>
                        </div>
                        <div class="goods_info_list_price">
                          <img class="money_icon" src="../goods/assets/img/money_icon.png"/>
                          <span>{{ item.price }}</span>
                        </div>
                        <div class="goods_info_list_remain">
                          <span>剩余：{{ item.num }}</span>
                        </div>
                        <van-button @click="toBuy(item.id)" class="to_buy">立即兑换</van-button>
                      </div>
                    </van-col>
                  </van-row>

                </van-tab>
              </van-tabs>
            </div>
            <div class="more_goods flex-col">
              <router-link target="_blank" to="/goods/list" class="more_goods_text">查看更多 +</router-link>
            </div>

          </div>

        </van-col>
      </van-row>

    </div>

    <Footer></Footer>


  </div>

</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import {newsList, goodsList, rankList, activityList, bannerList, background} from '../../utils/api/index'
import {goodsCategory} from '../../utils/api/goods'
// import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  components: {
    Header,
    Footer,
    // vueSeamlessScroll
  },
  data() {
    return {
      bannerList: [],
      goodsActive: 0,
      goodsCategory: 0,
      goodsCate: [],
      activities: [],
      background: {},
      ranks: [],
      notices: [],
      noticesTop: [],
      goods: [],
      constants: {},
      months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      month: '',
    };
  },
  methods: {
    handleChange(index) {
      this.goodsCategory = this.goodsCate[index]['id'];
      this.getGoodsList();
    },
    toBuy(index) {
      let routeData = this.$router.resolve({path: '/goods/detail', query: {id: index}});
      window.open(routeData.href, '_blank');
      // this.$router.push('/goods/detail?id=' + index)
    },
    getRankList() {
      rankList({}).then((res) => {
        if (res.data.code === 200) {
          this.ranks = res.data.data.points_ranking;
          this.month = this.months[res.data.data.month - 1]
        }
      })
    },
    async getGoodsList() {
      await goodsList({category_id: this.goodsCategory}).then((res) => {
        console.log(res.data.data)
        if (res.data.code === 200) {
          this.goods = res.data.data;
          // console.log(this.goods)
        }
      })
    },
    getNoticeList() {
      newsList().then((res) => {
        if (res.data.code === 200) {
          this.noticesTop = res.data.data.top;
          this.notices = res.data.data.list;
          // console.log(res.data.data)
        }
      })
    },
    getBannerList() {
      bannerList().then((res) => {
        if (res.data.code === 200) {
          const bannerList = res.data.data.result
          bannerList.forEach(function (item) {
            if (item.url === '') {
              item.url = '#';
            }
          });
          this.bannerList = bannerList;
        }
      })
    },
    getBackground() {
      background().then((res) => {
        if (res.data.code === 200) {
          this.background = res.data.data;
        }
      })
    },
    jumpTo(url) {
      if (url === '') {
        return;
      }
      window.open(url)
    },
    getActivityList() {
      activityList({}).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          let activities = res.data.data;
          activities.forEach(function (item) {
            if (item.status === '进行中') {
              item.color = '#FFAA00';
            } else {
              item.color = '#757575';
            }
          });
          this.activities = activities
        }
      })
    },
    async getGoodsCategory() {
      await goodsCategory({}).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.goodsCate = res.data.data;
          // console.log(this.goodsCate);
          this.goodsCategory = res.data.data[0].id;
          // this.getGoodsList();
        }
      })
    }
  },
  mounted() {
    this.getBackground();
    this.getRankList();
    this.getBannerList();
    this.getActivityList();
    this.getNoticeList();
    this.getGoodsCategory().then(() => {
      this.getGoodsList();
    })
  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>