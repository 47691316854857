<template>
  <div class="page flex-col">
    <Header></Header>
    <div class="group_2 flex-col">
      <div class="group_3 flex-row justify-between">
        <div class="box_8 flex-col justify-between">
          <Left></Left>
        </div>
        <div class="box_5 flex-col">
          <div class="box_9 flex-row justify-between">
            <div class="box_6 flex-col"></div>
            <span class="text_16">我的账户余额</span>
          </div>
          <div class="block_3 flex-col"></div>
          <div class="box_10 flex-row justify-between">
            <span class="text_17">星币余额：{{ user.star_currency }}</span>
            <div class="text-wrapper_3 flex-col">
              <router-link target="_blank" to="/goods/list" class="text_18">兑换商品</router-link>
            </div>
          </div>
          <div class="box_11 flex-row">
            <span class="text_19">余额：</span>
            <span class="text_20">{{ user.balance }}</span>
            <div class="text-wrapper_4 flex-col">
              <button @click="setShow(true)" class="text_21 pointer">申请提现</button>
            </div>
          </div>

          <div class="tabs">
            <van-tabs v-model="orderActive" @change="handleChange">
              <van-tab v-for="(type, index) in logTypes" :key="index" :title="type.name" :index="type">
              </van-tab>
            </van-tabs>
          </div>
          <div class="block_8 flex-col"></div>
          <div class="text-wrapper_25 flex-row">
            <span class="text_25">时间</span>
            <!--            <span class="text_26">类型</span>-->
            <span class="text_27">详情</span>
            <span class="text_28">金额/余额</span>
            <span class="text_29">状态</span>
          </div>
          <div class="box_13 flex-row" v-for="(item, index) in logList" :key="index">
            <span class="text_30">{{ item.created_at }}</span>
            <!--            <span class="text_31">{{ item.category }}</span>-->
            <span class="text_32">{{ item.detail }}</span>
            <div class="text-wrapper_6">
              <span class="text_33" :style="{color:item.color}">{{ item.amount }}</span>
              <span class="text_34">（{{ item.balance }}）</span>
            </div>
            <span class="text_35">{{ item.status }}</span>
          </div>


          <div class="box_23 flex-row" v-if="total_items>1">
            <van-pagination v-model="current_page" :total-items=total_items :items-per-page=per_page
                            :show-page-size="19"
                            @change="changePage"
                            force-ellipses>
              <template #prev-text>
                <van-icon name="arrow-left"/>
              </template>
              <template #next-text>
                <van-icon name="arrow"/>
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </div>
        </div>
      </div>
    </div>


    <van-overlay :show="show">
      <div class="withdrawal">
        <div class="withdrawal_close pointer" @click="show=false">
          <van-icon name="cross"/>
        </div>
        <div class="withdrawal_text">申请提现</div>
        <van-divider/>
        <van-form @submit="onSubmit" input-align="left">
          <van-field
              v-model="user.balance"
              readonly
              name="余额"
              label="余额"
          />

          <van-field
              type=number
              maxlength="6"
              v-model="form.amount"
              name="提现金额"
              label="提现金额"
              placeholder="请输入提现金额"
          />

          <van-field
              v-model="form.alipay_account"
              name="提现至支付宝"
              label="提现至支付宝"
              placeholder="请输入支付宝账户"
          />

          <van-field
              v-model="form.realname"
              name="支付宝实名"
              label="支付宝实名"
              placeholder="请输入支付宝实名姓名"
          />
          <van-button native-type="submit" class="submit_1">提交</van-button>
          <div class="withdrawal_notice">单个月只能提现一次</div>
        </van-form>
      </div>
    </van-overlay>

    <Footer></Footer>
  </div>


</template>
<script>
import Left from "../../components/Left";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {myWallet, userWithdrawal} from "../../utils/api/user"
import {Dialog, Toast} from "vant";


export default {
  components: {
    Left,
    Header,
    Footer,
  },
  data() {
    return {
      show: false,
      form: {
        amount: '',
        alipay_account: '',
        realname: '',
      },
      user: {
        balance: '',
        star_currency: '',
        id: '',
      },
      type: 'star_currency',
      page: 1,
      constants: {},
      orderActive: 0,
      current_page: 1,
      per_page: 10,
      total_items: 0,
      logTypes: [
        {
          'type_id': 0,
          'name': '星币记录',
          'type': 'star_currency',
        },
        {
          'type_id': 1,
          'name': '余额记录',
          'type': 'balance',
        },
        {
          'type_id': 2,
          'name': '提现记录',
          'type': 'withdrawal',
        }
      ],
      logList: [
        {
          'id': 1,
          'type': '顺丰到付',
          'detail': '这里是商品的标题',
          'money': '1232',
          'left_money': '1233',
          'status': '顺丰到付',
          'log_time': '顺丰到付',
        }
      ],
    };
  },
  methods: {
    // onSubmit() {
    //
    // },
    setShow(value) {
      this.show = value
    },
    changePage(page) {
      this.page = page;
      this.getMyWallet();
    },
    handleChange(index) {
      let that = this;
      this.logTypes.forEach(function (item) {
        if (item.type_id === index) {

          that.type = item.type
          that.getMyWallet();
        }
      })
    },
    getMyWallet() {
      let param = {
        page: this.page,
        category: this.type,
        per_page: this.per_page,
      };
      console.log(param);
      myWallet(param).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          const logList = res.data.data.list.data;
          logList.forEach(function (item) {
            console.log(item.type)
            if (item.type === 'income') {
              item.amount = '+' + item.amount;
              item.color = "red";
            } else {
              item.amount = '-' + item.amount;
              item.color = "black";
            }
          })
          this.logList = logList;
          this.per_page = res.data.data.list.per_page
          this.page = res.data.data.list.page
          this.total_items = res.data.data.list.total
          this.user = res.data.data.user
        }
      })
    },
    onSubmit() {
      if (parseInt(this.form.amount) > this.user.balance) {
        Toast.fail('余额不足！');
        return false;
      }
      if (!this.form.realname || !this.form.alipay_account) {
        Toast.fail('请完善信息！');
        return false;
      }
      Dialog.confirm({
        title: '申请提现',
        message: '确认您输入的数据无误',
      })
          .then(() => {
            // on confirm
            userWithdrawal(this.form).then((res) => {
              if (res.data.code === 200) {
                Toast.success('申请提现成功！');
                this.form.alipay_account = '';
                this.form.amount = 0;
                this.form.realname = '';
                this.show = false
              }
            })
          })
          .catch(() => {
            // on cancel
          });
    },
  },
  mounted() {
    this.getMyWallet();
  }

};
</script>
<style scoped lang="css" src="./assets/index.css"/>