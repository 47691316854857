import request from "../request";

// 资讯详情
export function newsDetail(params = {}) {
    return request({
        method: 'get',
        url: '/news/detail',
        params
    })
}

// 资讯列表
export function newsList(params = {}) {
    return request({
        method: 'get',
        url: '/news/list',
        params
    })
}