import axios from 'axios'
import {getToken, setToken, setUserInfo} from './token'
import {Toast} from "vant";

// 创建axios实例
const request = axios.create({
    // 这里可以放一下公用属性等。
    baseURL: process.env.VUE_APP_BASE_URL, // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。
    withCredentials: false, // 跨域请求时是否需要访问凭证
    timeout: 3 * 1000, // 请求超时时间

})

// 请求拦截器
request.interceptors.request.use((config) => {
    if (getToken('token')) {
        config.headers['Authorization'] = getToken('token'); //携带token
        config.headers['Content-type'] = 'application/json';
    }

    return config;
}, (error) => {
    return Promise.reject(error)
})

request.interceptors.response.use((response) => {
//返回码以自己的为定，如果没有安装elementui就换成自己的提示
    let {code, msg} = response.data
    if (code === 401) {
        setToken('');
        setUserInfo('');
        Toast.fail(msg);
        this.$route.push('/user/login')
        return false;
    }
    if (code !== 200) {
        if (msg.length < 10) {
            Toast.fail(msg);
        } else {
            Toast(msg);
        }
        return false;
    }
    return response;
}, (error) => {
    return Promise.reject(error)
})

export default request;