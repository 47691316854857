<template>
  <div class="page flex-col">
    <Header></Header>

    <div class="rank_top">
      <!--      <img class="activity_top_image" src="./assets/img/background.png">-->
    </div>

    <van-row class="rank_history">
      <van-col class="history">查看往期排名</van-col>
      <van-col>
        <select class="select_month" v-model="monthKey" @change="onMonthConfirm">
          <option class="month_option" v-for="(month, index) in months" :key="index" :value="index">{{ month }}</option>
        </select>
      </van-col>
      <!--      <van-col>-->
      <!--        <van-field-->
      <!--            readonly-->
      <!--            clickable-->
      <!--            name="picker"-->
      <!--            :value="monthValue"-->
      <!--            :value-key="monthKey"-->
      <!--            :default-index="monthKey"-->
      <!--            placeholder="点击选择月份"-->
      <!--            @click="showPicker = true"-->
      <!--        />-->
      <!--        <van-popup v-model="showPicker" position="bottom">-->
      <!--          <van-picker-->
      <!--              show-toolbar-->
      <!--              :columns="months"-->
      <!--              @confirm="onMonthConfirm"-->
      <!--              @cancel="showPicker = false"-->
      <!--          />-->
      <!--        </van-popup>-->
      <!--      </van-col>-->
    </van-row>


    <div class="rank_info">
      <van-row class="rank_list">
        <van-col class="rank_item_s">
          <van-row class="rank_level_s">
            S级创作者
          </van-row>
          <van-empty v-if="rank_s.length === 0" description="暂无排行"/>
          <van-row class="rank_item" v-for="(rank, index) in rank_s" :key="index">
            <van-col class="rank_item_name_box">
              <img :src=rank.rank_icon class="rank_item_name">
            </van-col>
            <van-col>
              <img class="rank_item_image" :src=rank.user.avatar>
            </van-col>
            <van-col class="rank_item_user">{{ rank.user.nickname }}</van-col>
            <van-col class="rank_item_score">{{ rank.points }}</van-col>
          </van-row>
        </van-col>

        <van-col class="rank_item_a">
          <van-row class="rank_level_a">
            A级创作者
          </van-row>
          <van-empty v-if="rank_a.length === 0" description="暂无排行"/>
          <van-row class="rank_item" v-for="(rank, index) in rank_a" :key="index">
            <van-col class="rank_item_name_box">
              <img :src=rank.rank_icon class="rank_item_name">
            </van-col>
            <van-col>
              <img class="rank_item_image" :src=rank.user.avatar>
            </van-col>
            <van-col class="rank_item_user">{{ rank.user.nickname }}</van-col>
            <van-col class="rank_item_score">{{ rank.points }}</van-col>
          </van-row>
        </van-col>

        <van-col class="rank_item_b">
          <van-row class="rank_level_b">
            B级创作者
          </van-row>
          <van-empty v-if="rank_b.length === 0" description="暂无排行"/>

          <van-row class="rank_item" v-for="(rank, index) in rank_b" :key="index">
            <van-col class="rank_item_name_box">
              <img :src=rank.rank_icon class="rank_item_name">
            </van-col>
            <van-col>
              <img class="rank_item_image" :src=rank.user.avatar>
            </van-col>
            <van-col class="rank_item_user">{{ rank.user.nickname }}</van-col>
            <van-col class="rank_item_score">{{ rank.points }}</van-col>
          </van-row>

        </van-col>
      </van-row>
    </div>


    <div class="rank_footer">
      <span class="my_rank">我的排名：{{ self_data.rank }}</span>
      <span class="my_score">积分：{{ self_data.points }}</span>
    </div>


    <Footer></Footer>

  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import {rankList} from "../../utils/api/rank"


export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      self_data: '',
      my_rank: '',
      my_points: '',
      showPicker: false,
      constants: {},
      list: {},
      months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      monthValue: '',
      monthKey: 0,
      rank_s: [],
      rank_a: [],
      rank_b: [],
    };
  },
  methods: {
    getRankList(monthKey) {
      let param = {
        month: parseInt(monthKey) + 1
      }
      console.log(param);
      rankList(param).then((res) => {
        let data = res.data.data;
        console.log(res);
        this.rank_s = data.list.S;
        this.rank_a = data.list.A;
        this.rank_b = data.list.B;

        let selfData = data.self;
        selfData.rank = selfData.rank ?? '-'
        selfData.points = selfData.points ?? '-'
        this.self_data = selfData
      })
      // return true;
    },
    onMonthConfirm(e) {
      let month = e.target.value;
      this.monthKey = month;
      this.monthValue = this.months[month];
      this.getRankList(this.monthKey);
    }
  },
  mounted() {
    let lastMonth = new Date().getMonth() - 1;
    if (lastMonth < 0) {
      lastMonth = 11
    }
    this.monthKey = lastMonth;
    this.monthValue = this.months[lastMonth];
    this.getRankList(this.monthKey);
  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>