<template>
  <div class="page flex-col">
    <Header></Header>

    <div class="activity_top">
<!--      <img class="activity_top_image" src="./assets/img/activity_center.png">-->
    </div>

    <div v-if="contents.length<1">
      <van-empty description="暂无资讯"/>
    </div>

    <div class="activity_info">
      <div class="activity_item" v-for="(content, index) in contents" :key="index" @click="handleClick(content.id)" >


        <van-col class="activity_image_info">
          <img class="activity_image" :src=content.cover>
        </van-col>

        <van-col class="activity_desc">
          <van-row class="activity_desc_title">
            <span class="">{{ content.title }}</span>
          </van-row>

          <!--          <van-row class="activity_desc_condition" v-for="(condition,index2) in activity.condition" :key="index2">-->
          <!--            <div class="text_8">{{ condition }}</div>-->
          <!--          </van-row>-->

          <van-row class="activity_desc_condition">
            <div class="text_8" v-html="content.content"></div>
          </van-row>


          <van-row class="activity_desc_time">
<!--            <span class="activity_desc_time_title">活动时间：</span>-->
            {{ content.created_at }}
          </van-row>
        </van-col>

<!--        <van-col class="activity_status">-->
<!--          <van-button class="status_button" :style="{backgroundColor:content.background_color,color:content.color}"-->
<!--                      @click="handleClick(content.id)">查看详情-->
<!--          </van-button>-->
<!--        </van-col>-->

      </div>
    </div>


    <Footer></Footer>

  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import {newsList} from "../../utils/api/news"


export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      page: 1,
      search: '',
      current_page: 1,
      per_page: 1000,
      total_items: 0,
      contents: [],
    };
  },
  methods: {
    handleClick(id) {
      let routeData = this.$router.resolve({ path: '/content/detail', query: { id: id } });
      window.open(routeData.href, '_blank');
      // this.$router.push('/activity/detail?id=' + id)
      // 这里是提交表单的代码
    },
    changePage(page) {
      this.page = page;
    },
    getList() {
      let param = {
        page: this.page,
        per_page: this.per_page,
      };
      newsList(param).then((res) => {
        if (res.data.code === 200) {
          let contents = res.data.data.data;
          contents.forEach(function (item) {
            item.background_color = '#FFA200';
          });
          this.contents = res.data.data.data;
          this.total_items = res.data.data.total;
        }
      })
    },
  },
  mounted() {
    this.getList();
  }
}
</script>
<style scoped lang="css" src="./assets/index.css"/>