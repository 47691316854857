<template>
  <div class="page flex-col">

    <div class="activity_info_box flex-col">
      <div class="activity_info_background">
        <div class="activity_info">
          <div>
            <img class="view_video pointer" src="./assets/img/guankan.png" alt="" @click="jumpTo('')">
          </div>
          <div class="activity_time">
            <!--            活动时间：{{ activity.from }}—{{ activity.to }}-->
          </div>
        </div>
      </div>
    </div>

    <div class="activity_desc_box flex-col">
      <div class="activity_desc">

        <div class="flex-row">
          <div class="activity_desc_top flex-col">

          </div>
          <div class="activity_desc_top_info flex-col">
            PUBG鸡斯卡盛典是一年一度备受期待的大型活动，由PUBG为聚合绝地求生生态所做出的正向价值内容，是创作者、玩家和平台的年度盛宴。这一活动汇聚了各个维度的优秀创作者、生态贡献者、最佳主播、赛事选手，齐聚线下分享优质内容与创作心得。
          </div>
        </div>
        <div class="information flex-row">
          作为PUBG创作者的最高荣誉，官方设置多种奖项的同时也带来了丰厚的专属奖励。2023年鸡斯卡盛典以“鸡斯卡派对”为主题，将游戏与惊喜派对等多种领域相结合，预计将于1月6日在上海隆重举办！
          <br>
          获奖者将受邀参加见证颁奖时刻！当天还有精彩的表演、独家消息发布和有趣的互动体验，将PUBG生态社区凝聚在一起，共享年度盛会。
        </div>
        <div class="flex-row information_top">
          <div class="flex-col information_left">
          </div>
          <div class="flex-col information_right">
            优秀作品收录期
          </div>
        </div>

        <div class="flex-row vote_information">
          2023年1月1日 - 10月30日的作品（日期范围内作品均可参与）
        </div>
        <div class="flex-row information_top">
          <div class="flex-col information_left">

          </div>
          <div class="flex-col information_right">
            优秀作品评选投票期
          </div>
        </div>
        <div class="flex-row vote_information">
          大众玩家投票环节: 2023.11.20 - 2023.11.30
        </div>
        <div class="flex-row vote_information">
          合作媒体投票环节: 2023.11.30 - 2023.12.10
        </div>
        <div class="flex-row vote_information">
          PUBG官方投票环节: 2023.12.10 - 2023.12.20
        </div>
        <div class="flex-row vote_information">
          严禁刷票行为， 一旦发现有刷票行为的会影响获奖评定。
        </div>
      </div>
    </div>

    <div class="activity_lottery_box flex-col">
      <div class="flex-row chapter_1">
        <img class="lottery_img" src="./assets/img/01.png">
      </div>

      <div class="flex-col lottery_times_total">
        每日共计20票，投完10票可获得一次抽奖机会
      </div>
      <div class="flex-row lottery_remain_times">
        <span class="lottery_remain_times_text">
          您当前剩余
          <span class="remain">{{ remain }}</span>
          次抽奖机会
        </span>
        <img class="refresh pointer" @click="reloadPage()" src="./assets/img/shuaxin.png">
      </div>


      <div class="flex-row lottery_box">
        <div class="flex-col activity_lottery">

          <div class="wrapper">
            <div
                class="prize-box"
                v-for="(prize, index) in prizes"
                :key="index"
                v-bind:class="{ active: luckyIndex === index }"
                @click="index === 4 && move2Prize()"
            >
              <div class="prize-img-box" v-if="index===4">
                <img class="prize-img-4 pointer" :src="prize.image"/>
              </div>
              <div class="prize-img-box" v-if="index!==4">
                <img class="prize-img" :src="prize.image"/>
              </div>
              <span class="prize-name" v-if="index!==4">{{ prize.name }}</span>
            </div>
          </div>

        </div>

        <van-overlay :show="show">
          <div class="withdrawal">
            <div class="gb_box"><img @click="show=false;allowBodyScroll()" class="gb" src="./assets/img/gb.png"></div>
            <div class="text_0">恭喜中奖</div>
            <div class="text_1">恭喜获得&nbsp;{{ prize.name }}</div>
            <div @click="copyData(prize.cdk)" class="text_2 pointer">{{ prize.cdk }}</div>
            <van-button @click="show=false;allowBodyScroll()" class="submit_1">好的</van-button>
            <div class="text_3">
              *请前往游戏自行兑换，及时保存，丢失不补。
            </div>
          </div>
        </van-overlay>

        <van-overlay :show="showThanks">
          <div class="withdrawal">
            <div class="gb_box"><img @click="showThanks=false;allowBodyScroll()" class="gb" src="./assets/img/gb.png">
            </div>
            <div class="text_4">
              谢谢惠顾
            </div>
          </div>
        </van-overlay>


        <div class="flex-col activity_reward">

          <div class="flex-col activity_reward_list">

            <div class="list_top flex-row">
              - 中奖记录 -
            </div>

            <div class="list_info_box">
              <vue-seamless-scroll :data="listData" class="warp">
                <div class="list_info flex-row" v-for="(item, index) in listData" :key="index">
                  <div class="list_info_user">恭喜 {{ item.user }} 抽中</div>
                  <div class="list_info_type">{{ item.gift.name }}</div>
                  <span class="list_info_date">{{ item.time_ago }}</span>
                </div>
              </vue-seamless-scroll>
            </div>

          </div>

          <div class="flex-col activity_reward_desc">
            <div class="reward_top flex-row">
              - 活动说明 -
            </div>
            <div class="reward_info flex-col">

              <div class="reward_info_text flex-col">
                · 每天完成10次投票可抽奖1次，共有两次抽奖机会
              </div>

              <div class="reward_info_text flex-col">
                · 投票机会不可累积,每日清零
              </div>
              <div class="reward_info_text flex-col">
                · 抽奖活动截止11月30日23:59:59
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="warning-message">中奖后请及时领取，遗失过期不补！</div>


    </div>


    <div class="activity_content_vote_box flex-col">

      <div class="flex-row chapter_2">
        <img class="vote_img" src="./assets/img/02.png">
      </div>

      <div class="flex-row vote_times">
        *每位用户每日可投10票，单个奖项上限2票
      </div>

      <div class="flex-col creative_editing_box">
        <div class="flex-row creative_editing">
          <span class="creative_editing_title">· 最佳创意剪辑奖 ·</span>
        </div>

        <div class="flex-col creative_editing_text">
          以创意内容为主，能体现独特的内容风格或者剪辑能力
        </div>
        <van-row class="videos_vote">
          <van-col v-for="(item, index) in cyjj" :key="index" class="list-item">
            <div class="video_info_list flex-col">
              <div class="video_info flex-row">
                <img class="video_list_img pointer" :src=item.image @click="jumpTo(item.url)">
              </div>
              <div class="flex-row video_title">{{ item.title }}</div>
              <div class="vote_info flex-row">
                <div class="flex-col vote_user">
                  <div class="flex-row vote_user">
                    <img class="vote_icon" src="./assets/img/yh.png">
                    <div class="vote_text">{{ item.nickname }}</div>
                  </div>
                  <div class="flex-row vote_num">
                    <img class="vote_icon" src="./assets/img/ps.png">
                    <div class="vote_text">票数:{{ item.vote }}</div>
                  </div>
                </div>
                <div class="flex-col to_vote">
                  <button class="to_vote_btn pointer" @click="toVote(1,item.id,cyjj)">投票</button>
                </div>
              </div>

            </div>

          </van-col>
        </van-row>
      </div>
    </div>

    <div class="flex-col vote_box_1">
      <div class="flex-row creative_editing vote_box_top_1">
        <span class="creative_editing_title">· 最佳剧情创作奖 ·</span>
      </div>

      <div class="flex-col creative_editing_text">
        以故事内容为主，能体现参赛作者剧情创作能力和叙事能力
      </div>
      <van-row class="videos_vote">
        <van-col v-for="(item, index) in jqcz" :key="index" class="list-item">
          <div class="video_info_list flex-col">
            <div class="video_info flex-row">
              <img class="video_list_img pointer" :src=item.image @click="jumpTo(item.url)">
            </div>
            <div class="flex-row video_title">{{ item.title }}</div>
            <div class="vote_info flex-row">
              <div class="flex-col vote_user">
                <div class="flex-row vote_user">
                  <img class="vote_icon" src="./assets/img/yh.png">
                  <span class="vote_text">{{ item.nickname }}</span>
                </div>
                <div class="flex-row vote_num">
                  <img class="vote_icon" src="./assets/img/ps.png">
                  <span class="vote_text">票数:{{ item.vote }}</span>
                </div>
              </div>
              <div class="flex-col to_vote">
                <button class="to_vote_btn pointer" @click="toVote(1,item.id,jqcz)">投票</button>
              </div>
            </div>

          </div>

        </van-col>
      </van-row>
    </div>

    <div class="flex-col vote_box_2">
      <div class="flex-row creative_editing vote_box_top_2">
        <span class="creative_editing_title">· 最佳视觉效果奖 ·</span>
      </div>

      <div class="flex-col creative_editing_text">
        以特效制作手法为主，能体现优秀的特效技巧运用以及视觉制作能力
      </div>
      <van-row class="videos_vote">
        <van-col v-for="(item, index) in sjxg" :key="index" class="list-item">
          <div class="video_info_list flex-col">
            <div class="video_info flex-row">
              <img class="video_list_img pointer" :src=item.image @click="jumpTo(item.url)">
            </div>
            <div class="flex-row video_title">{{ item.title }}</div>
            <div class="vote_info flex-row">
              <div class="flex-col vote_user">
                <div class="flex-row vote_user">
                  <img class="vote_icon" src="./assets/img/yh.png">
                  <span class="vote_text">{{ item.nickname }}</span>
                </div>
                <div class="flex-row vote_num">
                  <img class="vote_icon" src="./assets/img/ps.png">
                  <span class="vote_text">票数:{{ item.vote }}</span>
                </div>
              </div>
              <div class="flex-col to_vote">
                <button class="to_vote_btn pointer" @click="toVote(1,item.id,sjxg)">投票</button>
              </div>
            </div>

          </div>

        </van-col>
      </van-row>
    </div>

    <div class="flex-col vote_box_3">
      <div class="flex-row creative_editing vote_box_top_3">
        <span class="creative_editing_title">· 最佳趣味喜剧奖 ·</span>
      </div>

      <div class="flex-col creative_editing_text">
        以趣味、整活、搞笑内容为主，传播效果强，观众声量大
      </div>
      <van-row class="videos_vote">
        <van-col v-for="(item, index) in qwxj" :key="index" class="list-item">
          <div class="video_info_list flex-col">
            <div class="video_info flex-row">
              <img class="video_list_img pointer" :src=item.image @click="jumpTo(item.url)">
            </div>
            <div class="flex-row video_title">{{ item.title }}</div>
            <div class="vote_info flex-row">
              <div class="flex-col vote_user">
                <div class="flex-row vote_user">
                  <img class="vote_icon" src="./assets/img/yh.png">
                  <span class="vote_text">{{ item.nickname }}</span>
                </div>
                <div class="flex-row vote_num">
                  <img class="vote_icon" src="./assets/img/ps.png">
                  <span class="vote_text">票数:{{ item.vote }}</span>
                </div>
              </div>
              <div class="flex-col to_vote">
                <button class="to_vote_btn pointer" @click="toVote(1,item.id,qwxj)">投票</button>
              </div>
            </div>

          </div>

        </van-col>
      </van-row>
    </div>

    <div class="flex-col vote_box_4">
      <div class="flex-row creative_editing vote_box_top_4">
        <span class="creative_editing_title">· 最佳操作教学奖 ·</span>
      </div>

      <div class="flex-col creative_editing_text">
        以游戏操作视频为主，能体现优秀的枪法/身法以及淘汰技巧且引发观众共鸣
      </div>
      <van-row class="videos_vote">
        <van-col v-for="(item, index) in czjx" :key="index" class="list-item">
          <div class="video_info_list flex-col">
            <div class="video_info flex-row">
              <img class="video_list_img pointer" :src=item.image @click="jumpTo(item.url)">
            </div>
            <div class="flex-row video_title">{{ item.title }}</div>
            <div class="vote_info flex-row">
              <div class="flex-col vote_user">
                <div class="flex-row vote_user">
                  <img class="vote_icon" src="./assets/img/yh.png">
                  <span class="vote_text">{{ item.nickname }}</span>
                </div>
                <div class="flex-row vote_num">
                  <img class="vote_icon" src="./assets/img/ps.png">
                  <span class="vote_text">票数:{{ item.vote }}</span>
                </div>
              </div>
              <div class="flex-col to_vote">
                <button class="to_vote_btn pointer" @click="toVote(1,item.id,czjx)">投票</button>
              </div>
            </div>

          </div>

        </van-col>
      </van-row>
    </div>


    <div class="activity_ecological_vote_box flex-col">
      <div class="flex-row chapter_3">
        <img class="lottery_img" src="./assets/img/03.png">
      </div>

      <div class="flex-col lottery_times">
        *每位用户每日可投10票，单个奖项上限5票
      </div>

      <div class="flex-row ecological">
        <span class="ecological_title">· 2023 鸡斯卡星火计划-黑马新星奖 ·</span>
      </div>
      <div class="flex-col ecological_text">
        根据星火计划生态赛道数据评定，综合创作数量或质量增长、创作流量增长、粉丝量增长，个人积分评选
      </div>
      <van-row class="user_vote">
        <van-col v-for="(item, index) in hmxx" :key="index">
          <div class="user_info_list flex-col">
            <div class="user_info flex-row">
              <img class="user_list_img pointer" :src=item.avatar @click="jumpTo(item.url)">
            </div>
            <div class="flex-row user_name">{{ item.nickname }}</div>
            <div class="flex-row user_vote_num">票数:{{ item.vote }}</div>
            <button class="to_vote_user_btn pointer" @click="toVote(2,item.id,hmxx)">立即投票</button>
          </div>
        </van-col>
      </van-row>
    </div>

    <div class="flex-col hard_work">
      <div class="flex-row ecological">
        <span class="ecological_title">· 2023 鸡斯卡星火计划-勤劳创作奖 ·</span>
      </div>
      <div class="flex-col ecological_text">
        根据星火计划生态赛道数据评定，综合内容数量、内容更新频率个人积分/综合评选
      </div>
      <van-row class="user_vote">
        <van-col v-for="(item, index) in qlcz" :key="index">
          <div class="user_info_list flex-col">
            <div class="user_info flex-row">
              <img class="user_list_img pointer" :src=item.avatar @click="jumpTo(item.url)">
            </div>
            <div class="flex-row user_name">{{ item.nickname }}</div>
            <div class="flex-row user_vote_num">票数:{{ item.vote }}</div>
            <button class="to_vote_user_btn pointer" @click="toVote(2,item.id,qlcz)">立即投票</button>
          </div>
        </van-col>
      </van-row>
    </div>

    <div class="flex-col platform">
      <div class="flex-row chapter_4">
        <img class="chapter_4_img" src="./assets/img/04.png">
      </div>
      <div class="flex-col chapter_4_title">
        *排名不分先后
      </div>

      <div class="flex-row platform_list_4">
        <van-grid class="platform_list_items" :border="false" :column-num="list1.length">
          <van-grid-item class="platform_list_5_item" v-for="(item,index) in list1" :key="index">
            <img class="platform_info_img pointer" :src=item.logo alt="" @click="jumpTo(item.url)"/>
            <div class="platform_info_text">{{ item.name }}</div>
          </van-grid-item>
        </van-grid>
      </div>

    </div>


    <div class="flex-col platform">
      <div class="flex-col chapter_5">
        <img class="chapter_5_img" src="./assets/img/05.png">
      </div>
      <div class="flex-col chapter_4_title">
        *排名不分先后
      </div>

      <div class="flex-row platform_list_5">

        <van-grid class="platform_list_items" :border="false" :column-num=list2.length>
          <van-grid-item v-for="(item,index) in list2" :key="index">
            <img class="platform_info_img pointer" :src=item.logo alt="" @click="jumpTo(item.url)"/>
            <div class="platform_info_text">{{ item.name }}</div>
          </van-grid-item>
        </van-grid>

      </div>

    </div>

    <div class="flex-col footer">
      <div class="footer_platform">

      </div>
      <div class="footer_icon_box">
        <img class="footer_icon" src="./assets/img/pubg.png" alt="">
      </div>

      <div class="footer_policy">
        <a href="https://pubg.com/zh-cn/clause/privacy_policy/label_ka_website/latest" target="_blank">隐私政策</a> |
        <a href="https://pubg.com/zh-cn/clause/term_of_service/label_ka_website/latest" target="_blank">服务条款</a> |
        <a href="https://pubg.com/zh-cn/clause/term_of_service/label_ka_website/latest" target="_blank">行为规则</a> |
        <a href="https://pubg.com/zh-cn/clause/term_of_service/label_ka_website/latest" target="_blank">Cookie设置</a>
      </div>
      <div class="footer_copyright">
        <div class="footer_copyright_text">
          © 2023 KRAFTON, INC. PUBG是KRAFTON, INC.的注册商标或服务标志。
        </div>
      </div>

      <van-row class="footer_time">
        <van-col span="11"><span class="flex-row utc_time">UTC&ensp; {{ utc_time }}</span></van-col>
        <van-col span="2">
          <van-icon class="time_icon" name="clock-o"/>
        </van-col>
        <van-col span="11"><span class="flex-row cst_time">CST&ensp; {{ cst_time }}</span></van-col>
      </van-row>

      <div>
        <img class="footer_icon_svg" src="./assets/img/pubg.svg" alt="">
      </div>
    </div>


  </div>
</template>
<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import {jiscar, voting, lottery, lotteryRecord, platforms} from "../../utils/api/activity"
import {Toast} from "vant";
import CryptoJS from 'crypto-js';

export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      show: false,
      showThanks: false,
      cyjj: [],
      czjx: [],
      gift: [],
      hmxx: [],
      jqcz: [],
      qlcz: [],
      qwxj: [],
      sjxg: [],
      cst_time: null,
      cstTimerId: null,
      utc_time: null,
      utcTimerId: null,
      prizes: [],
      moveIndex: [
        0, 1, 2,
        5, 8,
        7, 6, 3
      ], //逆时针顺序
      remain: 0,
      activity: {
        from: '11月20日',
        to: '12月30日',
      },
      listData: [],
      timer: null,
      moving: false,
      luckyIndex: null,
      realLuckyIndex: null,
      timerSpeed: 100,
      selectedIndex: 0,
      prize: {},
      list1: [],
      list2: [],
    };
  },
  methods: {
    startCallBack() {
    },
    endCallBack() {
    },
    getContent() {
    },
    getData() {
      jiscar({}).then((res) => {
        if (res.data.code === 200) {
          let result = this.dataDecrypt(res.data.data);
          this.remain = result.lottery_num;
          if (result.lottery_num < 1) {
            this.remain = 0;
          }
          this.cyjj = result.cyjj;
          this.czjx = result.czjx;
          this.gift = result.gift;
          this.hmxx = result.hmxx;
          this.jqcz = result.jqcz;
          this.qlcz = result.qlcz;
          this.qwxj = result.qwxj;
          this.sjxg = result.sjxg;


          let firstFour = result.gift.slice(0, 4);
          let lastFour = result.gift.slice(4, 8);

          let five = [{
            "id": 1,
            "name": "",
            "image": require("./assets/img/choujiang.png"),
          }];
          this.prizes = [...firstFour, ...five, ...lastFour];
        }
      })
    },
    toVote(type, videoId, list) {
      let params = {
        allocation_id: videoId,
        type: type,
        sign: this.cst_time,
      };
      params = this.dataEncrypt(params);
      voting({data: params}).then((res) => {
        if (res.data.code === 200) {
          list.forEach(function (item) {
            if (item.id === videoId) {
              item.vote++;
              Toast.success('投票成功！');
              return true;
            }
          });
        }
      })
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
      this.moving = false;
    },
    getRealLuckyIndex() {
      lottery({}).then((res) => {
        if (res.data.code === 200) {
          let that = this;
          this.prizes.forEach(function (item, index) {
            if (item.id === res.data.data.id) {
              that.realLuckyIndex = index;
              that.prize = res.data.data;
              return true;
            }
          })
        }
      })
    },
    getLotteryRecord() {
      lotteryRecord({}).then((res) => {
        if (res.data.code === 200) {
          this.listData = this.dataDecrypt(res.data.data).data;
          // this.listData = res.data.data.data;
        }
      })
    },
    getPlatforms() {
      platforms({}).then((res) => {
        if (res.data.code === 200) {
          let result = this.dataDecrypt(res.data.data);
          this.list1 = result['zhibo'];
          this.list2 = result['zhuanti'];
        }
      })
    },
    move2Prize() {
      if (this.remain < 1) {
        Toast.fail('您没有抽奖机会了');
        return false;
      }
      if (this.moving === false) {
        this.remain--;
        this.getRealLuckyIndex();
        this.moving = true;
        var that = this;
        var count = 0;
        var total = 60;
        var times = 0;
        var getResult = false;
        this.timer = setInterval(function () {
          count++;
          if (times > 4 && that.realLuckyIndex) {
            that.luckyIndex = that.realLuckyIndex;
            getResult = true;
            count = total;
          }

          if (count >= total) {
            if (that.realLuckyIndex === null || that.realLuckyIndex === undefined) {
              Toast.fail('操作失败');
            }
            // 弹窗
            that.popData();
            that.realLuckyIndex = null;
            that.selectedIndex = 0;
            that.clearTimer();
            return false;
          }

          if (that.selectedIndex >= that.moveIndex.length) {
            times++;
            that.selectedIndex = 0;
          }

          if (getResult === false) {
            that.luckyIndex = that.moveIndex[that.selectedIndex];
          }

          that.selectedIndex++;

        }, this.timerSpeed);
      } else {
        Toast.fail("正在抽奖中..");
      }
    },
    reloadPage() {
      location.reload();
    },
    popData() {
      if (this.prize.id === 0) {
        this.showThanks = true;
        this.stopBodyScroll();
      }
      if (this.prize.id > 0) {
        this.show = true;
        this.stopBodyScroll();
      }
    },
    copyData(text) {
      navigator.clipboard.writeText(text).then(() => {
        Toast.success('CDK已复制!');
      });
    },
    stopBodyScroll() {
      document.body.style.overflow = 'hidden';
    },
    allowBodyScroll() {
      document.body.style.overflow = '';
      this.prize = {};
    },
    dataEncrypt(word) {
      const keyStr = process.env.VUE_APP_ENCRYPT_KEY;
      const ivStr = process.env.VUE_APP_ENCRYPT_IV;
      let key = CryptoJS.enc.Utf8.parse(keyStr);
      let iv = CryptoJS.enc.Utf8.parse(ivStr)
      let srcs = JSON.stringify(word);

      let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    },
    dataDecrypt(word) {
      const keyStr = process.env.VUE_APP_ENCRYPT_KEY;
      const ivStr = process.env.VUE_APP_ENCRYPT_IV;
      let key = CryptoJS.enc.Utf8.parse(keyStr);
      let iv = CryptoJS.enc.Utf8.parse(ivStr)

      let decrypt = CryptoJS.AES.decrypt(word, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      return JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));
    },
    jumpTo(url) {
      if (url === '') {
        // Toast.success('敬请期待!');
        Toast({
          message: '敬请期待!',
          icon: require('./assets/img/favicon.png'),
        });
        return;
      }
      window.open(url)
    },
  },
  mounted() {
    this.getData();
    this.getPlatforms();
    this.getLotteryRecord();
    this.utcTimerId = setInterval(() => {
      this.utc_time = new Date().toLocaleString('en-CN', {timeZone: 'UTC', hour12: false})
    }, 1000);
    this.cstTimerId = setInterval(() => {
      const now = new Date();
      const cstOffset = 8 * 60 * 60 * 1000;
      this.cst_time = new Date(now.getTime() + cstOffset).toLocaleString('zh-CN', {timeZone: 'UTC', hour12: false})
    }, 1000);
  },
  beforeDestroy() {
    this.clearTimer();
    clearInterval(this.utcTimerId);
    clearInterval(this.cstTimerId);
  },
};
</script>
<style scoped lang="css" src="./assets/index.css"/>
<style scoped lang="css" src="./assets/lucky.css"/>