<template>
  <div class="page flex-col">
    <div class="back">
      <div class="form flex-col">
        <van-form input-align="left">
          <van-field class="phone" v-model="account" placeholder="请输入用户名称"/>
          <van-field class="phone" v-model="password" placeholder="请输入登录密码" type="password"/>
          <van-row class="info">
            <van-col class="agree">还没有账户?</van-col>
            <van-col>
              <router-link target="_blank" to="/user/register" class="register">立即注册</router-link>
            </van-col>
            <!--          <van-col class="forget">-->
            <!--            <router-link target="_blank" to="/user/reset_password">忘记密码?</router-link>-->
            <!--          </van-col>-->
          </van-row>
          <van-button class="login" type="primary" @click="handleSubmit">登录</van-button>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>

import {Toast} from "vant";
import {login,record} from "../../utils/api/user"
import {setToken, setUserInfo, getToken} from "../../utils/token"

export default {
  data() {
    return {
      account: '',
      password: '',
    }
  },
  methods: {
    onSubmit1() {

    },
    recordUser(){
      record({}).then((res) => {
        console.log(res)
      });
    },
    handleSubmit() {
      if (!this.account) {
        Toast.fail('请输入用户名')
        return
      }

      if (!this.password) {
        Toast.fail('请输入密码')
        return
      }
      let param = {
        account: this.account,
        password: this.password,
        code: this.code,
        hash_key: '',
      };
      login(param).then((res) => {
        let token = res.data.data.auth.token_type + ' ' + res.data.data.auth.access_token
        // console.log(token);return;
        let user_info = res.data.data.user_info;
        // console.log(res);return;
        if (token && user_info) {
          setToken(token)
          setUserInfo(user_info)
        }
        Toast.success('登录成功！');
        this.$router.push('/index')
      })
    },
  },
  mounted() {
    this.recordUser();
    if (getToken() !== '') {
      this.$router.push('/user/info');
    }
  }
}
</script>
<style scoped lang="css" src="./assets/index.css"/>
