<template>
  <div class="page flex-col">
    <div class="back">
      <div class="form flex-col">
        <van-field class="phone" v-model="account" placeholder="请设置账号"/>
        <van-field class="phone" v-model="password" placeholder="请设置6-20位登录密码" type="password"/>
        <van-field @blur="confirmPass" class="phone" v-model="confirm_password" placeholder="确认密码" type="password"
        />
        <van-field class="phone" v-model="nickname" placeholder="请设置昵称/建议同主平台昵称"/>
        <!--      <van-field class="phone" v-model="link" placeholder="平台主链接" required/>-->
        <van-checkbox class="agreed" v-model="agreed" @change="handleAgreedChange">
          <span class="agree">同意勾选</span>
          <a href="https://pubg.com/zh-cn/clause/privacy_policy/label_ka_website/latest" target="_blank" class="xieyi">《用户服务协议》</a>
          <a href="https://pubg.com/zh-cn/clause/term_of_service/label_ka_website/latest" target="_blank" class="xieyi">《隐私协议》</a>
        </van-checkbox>
        <van-button class="register" type="primary" @click="handleSubmit">注册</van-button>

        <div class="login pointer" @click="toLogin"> 已有账户，立即登录</div>
      </div>
    </div>
  </div>
</template>

<script>

import {Toast} from "vant";
import {register} from "../../utils/api/user"


export default {
  data() {
    return {
      // phone: '',
      // captcha: '',
      // password: '',
      account: '',
      password: '',
      nickname: '',
      confirm_password: '',
      // text: '获取验证码',
      agreed: false,
      countdown: '',
      // link: '',
    }
  },
  methods: {
    handleAgreedChange(value) {
      if (!value) {
        Toast.fail('请阅读并同意用户协议')
      }
    },
    handleSubmit() {
      if (!this.agreed) {
        Toast.fail('请阅读并同意用户协议')
        return
      }
      if (this.password.length > 20 || this.password.length < 8) {
        Toast.fail('密码8至20位！')
        return
      }
      if (this.confirm_password !== this.password) {
        Toast.fail('前后密码不一致！')
        return;
      }

      // const pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])[A-Za-z\d@#$%^&+=!]{8,20}/;
      // if (!this.password.match(pattern)) {
      //   Toast.fail('密码必须包含大小写字母 数字 且长度8至20位！')
      // }

      if (this.nickname.length > 20 || this.nickname.length < 6) {
        Toast.fail('请输入6-20位昵称')
        return;
      }
      if (this.account.length > 20 || this.account.length < 6) {
        Toast.fail('请输入6-20位登录账户')
        return;
      }
      let formData = new FormData();
      formData.append('account', this.account);
      formData.append('nickname', this.nickname);
      formData.append('password', this.password);
      register(formData).then((res) => {
        if (res.data.code === 200) {
          Toast.success('注册成功！');
          this.$router.push('/user/login')
        } else {
          Toast.fail(res.data.msg);
        }
      })
      // 这里是提交表单的代码
    },
    showCaptcha() {
      this.text = '';
      Toast.success('获取成功，请注意您的短信验证码！')
      this.countdown = 10;
      this.countdownInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          this.text = '获取验证码';
          this.countdown = '';
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    },
    confirmPass() {
      if (this.confirm_password !== this.password) {
        Toast.fail('前后密码不一致！')
      }
    },
    toLogin() {
      this.$router.push('/user/login')
    }
    // Toast.success('请阅读并同意用户协议')
  }
}
</script>
<style scoped lang="css" src="./assets/index.css"/>