import request from "../request";


// 排行榜
export function rankList(params = {}) {
    return request({
        method: 'get',
        url: '/points_ranking/ranking',
        params
    })
}