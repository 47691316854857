<template>
  <div class="page flex-col">
    <Header></Header>
    <div class="group_2 flex-col">
      <div class="box_9 flex-row justify-between">

        <Left></Left>

        <van-form @submit="submitForm" enctype="multipart/form-data">
          <div class="box_5 flex-col">
            <span class="text_16">提交我的数据</span>

            <van-tabs v-model="dataActive" @change="handleChange">
              <van-tab v-for="(item, index) in dataItems" :key="index" :title="item.name" :index="index">
              </van-tab>
            </van-tabs>

            <div class="box_6">
            </div>
            <div class="block_3 flex-row">
              <van-radio-group v-model="platform_id">
                <van-radio class="text_21" v-for="(item,index) in platforms" :key="index" :name="item.id">
                  {{ item.name }}
                  <img
                      class="label_6"
                      referrerpolicy="no-referrer"
                      :src="item.logo"
                  />
                </van-radio>

                <!--                <van-radio name="douyin">-->
                <!--                  <img-->
                <!--                      class="label_6"-->
                <!--                      referrerpolicy="no-referrer"-->
                <!--                      src="./assets/img/SketchPngc5645113b2b41581ef29b0ed9a878bd70905e18af00aaccb58829c8c49a2a475.png"-->
                <!--                  />-->
                <!--                  <span class="text_21">抖音</span>-->
                <!--                </van-radio>-->
              </van-radio-group>
            </div>

            <div class="upload_file">
              <van-uploader
                  name="file"
                  v-model="fileList"
                  :max-count="1"
                  :after-read="afterRead"
                  accept=".csv,.xlsx,.xls"
              >
                <van-button class="up">上传表格数据</van-button>
              </van-uploader>
            </div>


            <div class="text-wrapper_10 flex-row justify-between">
              <span class="text_23">导出表格示意图</span>
              <span class="text_24">*导出后请勿修改表格内容，若有修改直接取消积分资格。</span>
            </div>
            <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng19e9dcff515f2bc626c904376971d1c493ad01bbc3543980e7d04c9a2b0ceb0b.png"
            />
            <!--          <div class="text-wrapper_6 flex-col" v-if="fileList.length > 0">-->
            <!--            <button class="text_25">已提交</button>-->
            <!--          </div>-->
            <!--          -->
            <!--          <div class="text-wrapper_7 flex-col" v-if="fileList.length < 1">-->
            <!--            <button class="text_26" @click="submitFile">立即提交</button>-->
            <!--          </div>-->
            <!--          -->
            <div class="text-wrapper_7 flex-col">
              <button class="text_26">立即提交</button>
            </div>
          </div>
        </van-form>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Left from "../../components/Left";
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import {submitData, getPlatform} from "../../utils/api/user"
import {uploadFile} from "../../utils/api/upload"
import {Toast} from "vant";


export default {
  components: {
    Left,
    Header,
    Footer
  },
  data() {
    return {
      constants: {},
      dataActive: 0,
      type: '',
      platforms: [],
      platform_id: 'douyin',
      fileList: [],
      file_url: '',
      dataItems: [
        {
          'id': 1,
          'name': '上周数据提交',
          'extra': 'last_week'
        },
        {
          'id': 2,
          'name': '上月数据提交',
          'extra': 'last_month'
        },
      ],
    };
  },
  methods: {
    handleChange(index) {
      let that = this;
      this.dataItems.forEach(function (item) {
        if (item.id === index + 1) {
          that.type = item.extra;
          return true;
        }
      });
    },
    afterRead() {
      let that = this;
      uploadFile(this.fileList[0].file).then((res) => {
        that.file_url = res;
        console.log(that.file_url);
      });
    },
    handleAvatarChange(a) {
      console.log(a)
    },
    submitForm() {
      if (this.file_url === '') {
        Toast.fail('请上传文件！')
        return
      }
      const formData = new FormData();
      formData.append('type', this.type);
      formData.append('file_url', this.file_url);
      formData.append('platform_id', this.platform_id);
      submitData(formData).then((res) => {
        if (res.data.code === 200) {
          Toast.success('提交成功！');
        }
      })
    },
    getPlatformData() {
      getPlatform().then((res) => {
        this.platforms = res.data.data;
      });
    },
  },
  mounted() {
    this.getPlatformData();
    this.handleChange(0);
  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>