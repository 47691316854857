import request from "../request";

// 活动详情
export function activityDetail(params = {}) {
    return request({
        method: 'get',
        url: '/activity/detail',
        params
    })
}

// 活动列表
export function activityList(params = {}) {
    return request({
        method: 'get',
        url: '/activity/list',
        params
    })
}
// 活动报名
export function activityAnroll(data) {
    return request({
        method: 'post',
        url: '/activity/anroll',
        data: data
    })
}

// 活动报名
export function activityContribute(data) {
    return request({
        method: 'post',
        url: '/activity/contribute',
        data: data
    })
}
export function jiscar(data) {
    return request({
        method: 'get',
        url: '/jiscar/index',
        data: data
    })
}


export function voting(data) {
    return request({
        method: 'post',
        url: '/jiscar/voting',
        data: data
    })
}

export function lottery(data) {
    return request({
        method: 'post',
        url: '/jiscar/lottery',
        data: data
    })
}


export function lotteryRecord(data) {
    return request({
        method: 'get',
        url: '/jiscar/get_lottery_record',
        data: data
    })
}

export function platforms(data) {
    return request({
        method: 'get',
        url: '/jiscar/get_platform',
        data: data
    })
}

