import 'vant/lib/index.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {Button, DropdownMenu, DropdownItem, Cell, Switch, Field} from 'vant'
import {RadioGroup, Radio} from 'vant';
import {CheckboxGroup, Checkbox} from 'vant';
import {Tab, Tabs} from 'vant';
import {Tabbar, TabbarItem} from 'vant';
import {NavBar, Icon, Tag} from 'vant';
import {Col, Row} from 'vant';
import {Toast} from 'vant';
import {Uploader} from 'vant';
import {DatetimePicker} from 'vant';
import {Picker} from 'vant';
import {Search} from 'vant';
import {Pagination} from 'vant';
import {Form} from 'vant';
import {Popup} from "vant";
import {Stepper} from "vant";
import {Empty} from "vant";
import {Swipe, SwipeItem} from 'vant';
import {Sidebar, SidebarItem} from 'vant';
import {Overlay} from 'vant';
import {Divider} from 'vant';
import {Grid,GridItem} from 'vant';
import VueWechatTitle from 'vue-wechat-title' //动态修改title


import './utils/rem'
import './utils/request'

Vue.use(VueWechatTitle);
Vue.component('van-field', Field)
Vue.component('van-button', Button)
Vue.component('van-dropdown-menu', DropdownMenu)
Vue.component('van-dropdown-item', DropdownItem)
Vue.component('van-cell', Cell)
Vue.component('van-switch', Switch)
Vue.component('van-checkbox-group', CheckboxGroup)
Vue.component('van-checkbox', Checkbox)
Vue.component('van-radio-group', RadioGroup)
Vue.component('van-radio', Radio)
Vue.component('van-tab', Tab)
Vue.component('van-tabs', Tabs)
Vue.component('van-tabbar', Tabbar)
Vue.component('van-tabbar-item', TabbarItem)
Vue.component('van-nav-bar', NavBar)
Vue.component('van-icon', Icon)
Vue.component('van-tag', Tag)
Vue.component('van-col', Col)
Vue.component('van-row', Row)
Vue.component('van-toast', Toast)
Vue.component('van-uploader', Uploader)
Vue.component('van-datetime-picker', DatetimePicker)
Vue.component('van-search', Search)
Vue.component('van-pagination', Pagination)
Vue.component('van-form', Form)
Vue.component('van-picker', Picker)
Vue.component('van-popup', Popup)
Vue.component('van-stepper', Stepper)
Vue.component('van-empty', Empty)
Vue.component('van-swipe', Swipe)
Vue.component('van-swipe-item', SwipeItem)
Vue.component('van-sidebar', Sidebar)
Vue.component('van-sidebar-item', SidebarItem)
Vue.component('van-overlay', Overlay)
Vue.component('van-divider', Divider)
Vue.component('van-grid', Grid)
Vue.component('van-grid-item', GridItem)


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
