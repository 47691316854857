<template>
  <div class="page flex-col">
    <Header></Header>
    <div class="group_1 flex-col">

      <div class="main">
        <img src="../index/assets/img/back_new.jpg" class="main_image" alt=""/>

        <!-- 遮罩 -->
        <div class="mask"></div>
      </div>

      <div class="box_1 flex-col">
        <span class="text_1">{{ activity.title }}</span>
        <div class="text-wrapper_1 flex-row justify-between">
          <span class="text_2">活动时间：</span>
          <span class="text_3">{{ activity.begin_time }}-{{ activity.end_time }}</span>
        </div>
      </div>
    </div>

    <div class="content_box">
      <div v-html="activity.content"></div>
    </div>

    <div class="join_activity">
<!--      <button v-if="contribute_btn===1" @click="signUpActivity" class="join_activity_text">立即报名</button>-->
<!--      <button v-if="anroll_btn===1" @click="joinActivity()"-->
<!--              class="join_activity_text">立即投稿-->
<!--      </button>-->
    </div>


    <!--  报名弹框-->
    <van-overlay :show="join_activity_show">
      <div class="join_activity_box">
        <div class="join_activity_close pointer" @click="join_activity_show=false;allowBodyScroll()">
          <van-icon name="cross"/>
        </div>
        <div class="join_activity_title">{{ activity_type.name }}投稿</div>
        <van-divider/>
        <van-form @submit="joinActivitySubmit" input-align="left">
          <div class="flex-row topic_items">
            <div class="flex-col topic_left">话题</div>
            <div class="flex-col topic_right">
              <!--              <van-checkbox-group v-model="selects" :max="3">-->
              <!--                <van-checkbox class="topic_row" v-for="(type,index) in activity_relation" :name=type.id-->
              <!--                              :key="index" shape="square">-->
              <!--                  {{-->
              <!--                    type.name-->
              <!--                  }}-->
              <!--                </van-checkbox>-->
              <!--              </van-checkbox-group>-->

              <van-radio-group v-model="selects" readonly>
                <van-radio class="topic_row" v-for="(type,index) in activity_relation" :name=type.id :key="index"
                           shape="square">
                  {{ type.name }}
                </van-radio>
              </van-radio-group>

            </div>
          </div>
          <div class="flex-row topic_items">
            <div class="flex-col topic_left">链接</div>
            <div class="flex-col">
              <textarea class="topic_url" type="text" v-model="url">

              </textarea>
            </div>
          </div>
          <van-button native-type="submit" class="submit_topic">提交</van-button>

        </van-form>
      </div>
    </van-overlay>


    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import {activityDetail, activityAnroll, activityContribute} from "../../utils/api/activity"

import {Toast} from "vant";

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      join_activity_show: false,
      topic_activity_show: false,
      activity_type: {},
      contribute_btn: 0,// 投稿按钮
      anroll_btn: 0, // 报名按钮
      url: '',
      activity_relation: [],
      selects: 0,
      constants: {},
      activity: {}
    };
  },
  methods: {
    getContent() {
      let param = {
        id: this.id,
      };
      activityDetail(param).then((res) => {
        if (res.data.code === 200) {
          this.activity = res.data.data
          this.activity_type = res.data.data.activity_category;
          this.activity_relation = res.data.data.activity_relation;
          this.contribute_btn = res.data.data.contribute_btn ? res.data.data.contribute_btn : 0;
          this.anroll_btn = res.data.data.anroll_btn ? res.data.data.anroll_btn : 0;

          console.log(this.new)
        }
      })
    },
    joinActivity() {
      this.join_activity_show = true;
      this.stopBodyScroll();
    },
    joinActivitySubmit() {
      if (this.url === '') {
        Toast.fail('请输入链接');
        return false;
      }
      if (this.selects.length === 0) {
        Toast.fail('请选择话题');
        return false;
      }
      const formData = new FormData();
      formData.append('id', this.id);
      formData.append('url', this.url);
      formData.append('relation_id', this.selects);

      activityContribute(formData).then((res) => {
        if (res.data.code === 200) {
          Toast.success('投稿成功');
          this.join_activity_show = false;
          this.allowBodyScroll();
        }
      })
    },
    stopBodyScroll() {
      document.body.style.overflow = 'hidden';
    },
    allowBodyScroll() {
      document.body.style.overflow = '';
    },
    signUpActivity() {
      activityAnroll({id: this.activity.id}).then((res) => {
        if (res.data.code === 200) {
          Toast.success('报名成功');
        }
      })
    },
  },
  mounted() {
    this.id = this.$router.currentRoute.query.id;
    if (this.id === undefined || this.id < 1) {
      Toast.fail('请选择对应资讯');
      this.$router.push('/index');
      return;
    }
    this.getContent();
  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>