import axios from 'axios'
import {Toast} from "vant";


export function uploadFile(file) {
    let formData = new FormData();
    formData.append("file", file);
    return axios({
        url: process.env.VUE_APP_BASE_URL + "/file_upload/index",
        method: "POST",
        data: formData
    }).then(result => {
        if (result.data.code !== 200) {
            Toast.fail('文件上传失败');
            return false;
        }
        return result.data.data.file_url;
    })
        .catch(err => {
            Toast.fail(err);
        });
}

export function sendBinaryFile(file) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Uint8Array(reader.result);
}

// export function uploadFile(file) {
//     let formData = new FormData();
//     formData.append("file", file);
//     axios({
//         url: "https://api.pubgjiscar.com/file_upload/index",
//         method: "POST",
//         data: formData
//     }).then(result => {
//             if (result.data.code !== 200) {
//                 Toast.fail('文件上传失败');
//                 return false;
//             }
//             return result.data.data.file_url; e
//         })
//         .catch(err => {
//             Toast.fail(err);
//         });
// }