import request from "../request";

// 首页获取咨询列表
export function newsList(params = {}) {
    return request({
        method: 'get',
        url: '/index/get_news',
        params
    })
}

// 活动列表
export function activityList(params = {}) {
    return request({
        method: 'get',
        url: '/index/get_activity',
        params
    })
}

// 榜单列表
export function rankList(params = {}) {
    return request({
        method: 'get',
        url: '/index/get_month_points_ranking',
        params
    })
}

// 商品列表
export function goodsList(params = {}) {
    return request({
        method: 'get',
        url: '/index/get_goods',
        params
    })
}

// banner列表
export function bannerList(params = {}) {
    return request({
        method: 'get',
        url: '/index/get_banner',
        params
    })
}

// 获取背景图
export function background(params = {}) {
    return request({
        method: 'get',
        url: '/index/get_bg_image',
        params
    })
}