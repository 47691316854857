<template>
  <div class="page flex-col">
    <Header></Header>

    <div class="activity_top">
      <img class="activity_top_image" src="./assets/img/activity_center.png">
    </div>

    <div v-if="activities.length<1">
      <van-empty description="暂无活动"/>
    </div>

    <div class="activity_info">
      <div class="activity_item" v-for="(activity, index) in activities" :key="index">
        <van-col class="activity_image_info">
          <img class="activity_image" :src=activity.image>
        </van-col>

        <van-col class="activity_desc">
          <van-row class="activity_desc_title">
            <span class="">{{ activity.title }}</span>
          </van-row>

          <!--          <van-row class="activity_desc_condition" v-for="(condition,index2) in activity.condition" :key="index2">-->
          <!--            <div class="text_8">{{ condition }}</div>-->
          <!--          </van-row>-->

          <van-row class="activity_desc_condition">
            <div class="text_8" v-html="activity.content"></div>
          </van-row>


          <van-row class="activity_desc_time">
            <span class="activity_desc_time_title">活动时间：</span>
            <span class="activity_desc_from_to">{{ activity.begin_time }}-{{ activity.end_time }}</span>
          </van-row>
        </van-col>

        <van-col class="activity_status">
          <van-button class="status_button" :style="{backgroundColor:activity.background_color,color:activity.color}"
                      @click="handleClick(activity.id)">{{activity.text}}
          </van-button>
        </van-col>

      </div>
      <!--      <div class="box_14 flex-row">-->
      <!--        <van-pagination v-model="current_page" :total-items=total_items :items-per-page=per_page-->
      <!--                        :show-page-size="19"-->
      <!--                        @change="changePage"-->
      <!--                        force-ellipses>-->
      <!--          <template #prev-text>-->
      <!--            <van-icon name="arrow-left"/>-->
      <!--          </template>-->
      <!--          <template #next-text>-->
      <!--            <van-icon name="arrow"/>-->
      <!--          </template>-->
      <!--          <template #page="{ text }">{{ text }}</template>-->
      <!--        </van-pagination>-->
      <!--      </div>-->
    </div>


    <Footer></Footer>

  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import {activityList} from "../../utils/api/activity"


export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      page: 1,
      search: '',
      current_page: 1,
      per_page: 10,
      total_items: 0,
      activities: [],
    };
  },
  methods: {
    handleClick(id) {
      let routeData = this.$router.resolve({ path: '/activity/detail', query: { id: id } });
      window.open(routeData.href, '_blank');
      // this.$router.push('/activity/detail?id=' + id)
      // 这里是提交表单的代码
    },
    changePage(page) {
      this.page = page;
    },
    getList() {
      let param = {
        page: this.page,
        per_page: this.per_page,
      };
      activityList(param).then((res) => {
        if (res.data.code === 200) {
          let activities = res.data.data.data;
          activities.forEach(function (item) {
            if (item.status === '进行中') {
              item.background_color = '#FFA200';
              item.color = '#000';
              item.text = '参与活动';
            }else{
              item.background_color = '#C8C8C8';
              item.color = '#fff';
              item.text = '活动回顾';
            }
          });
          // console.log(activities)
          this.activities = res.data.data.data;
          this.total_items = res.data.data.total;
          return
        }
      })
    },
  },
  mounted() {
    this.getList();
  }
}
</script>
<style scoped lang="css" src="./assets/index.css"/>