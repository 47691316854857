import request from "../request";

export function login(data) {
    return request({
        method: 'post',
        url: '/user/login',
        data: data
    })
}

export function logOut(data) {
    return request({
        method: 'post',
        url: '/user/logout',
        data: data
    })
}

// 获取平台信息
export function getUserPlatInfo(data) {
    return request({
        method: 'get',
        url: '/user/get_user_info',
        data: data
    })
}

// 提现
export function userWithdrawal(data) {
    return request({
        method: 'post',
        url: '/user/withdrawal',
        data: data
    })
}

//
// // 获取我的钱包
// export function myWallet(data) {
//     return request({
//         method: 'get',
//         url: '/user/my_wallet',
//         data: data
//     })
// }

// 更新用户信息
export function updateUserInfo(data) {
    return request({
        method: 'post',
        url: '/user/sub_user_info',
        data: data
    })
}

// 更新平台信息
export function updatePlatformInfo(data) {
    return request({
        method: 'post',
        url: '/user/sub_platform_info',
        data: data
    })
}

// 我的钱包
export function myWallet(params = {}) {
    return request({
        method: 'get',
        url: '/user/my_wallet',
        params
    })
}

// 我的订单
export function myOrder(params = {}) {
    return request({
        method: 'get',
        url: '/user/my_order',
        params
    })
}

// 获取平台列表
export function getPlatform(params = {}) {
    return request({
        method: 'get',
        url: 'user/get_platform',
        params
    })
}

// 提交工单
export function submitWorkOrder(data) {
    return request({
        method: 'post',
        url: '/user/sub_work_order',
        data: data
    })
}

// 提交数据
export function submitData(data) {
    return request({
        method: 'post',
        url: '/user/sub_user_data',
        data: data
    })
}

export function register(data) {
    return request({
        method: 'post',
        url: '/user/register',
        data: data
    })
}


export function getUserData(data) {
    return request({
        method: 'post',
        url: '/user/user_data',
        data: data
    })
}

export function getPointRule(data) {
    return request({
        method: 'get',
        url: '/user/points_rule',
        data: data
    })
}

export function record(data) {
    return request({
        method: 'get',
        url: '/user/access_log',
        data: data
    })
}


