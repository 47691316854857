export function setToken(token) {
    return localStorage.setItem('token', token)
}

export function getToken() {
    return localStorage.getItem('token')
}

export function setUserInfo(userInfo) {
    return localStorage.setItem('user_data', JSON.stringify(userInfo))
}

export function getUserInfo() {
    return JSON.parse(localStorage.getItem('user_data'))
}

export function removeToken() {
    return localStorage.removeItem('token')
}