<template>
  <div class="footer flex-col">

<!--    <div class="footer_image">-->
<!--      <img-->
<!--          class="footer_image_image"-->
<!--          referrerpolicy="no-referrer"-->
<!--          src="./images/footer_background.png"-->
<!--      />-->
<!--    </div>-->

    <div class="footer_content flex-col">
      <div class="footer_top flex-row">
        <img
            class="footer_top_image"
            referrerpolicy="no-referrer"
            src="./images/pubs.png"
        />
        <span class="text-group_8">创作者中心</span>
      </div>


      <div class="footer_info">
        <div class="text_xieyi">
          <a href="https://pubg.com/zh-cn/clause/privacy_policy/label_ka_website/latest" target="_blank">《用户服务协议》</a> |
          <a href="https://pubg.com/zh-cn/clause/term_of_service/label_ka_website/latest" target="_blank">《隐私协议》</a>
        </div>

        <div class="text_notice">
          温馨提示：抵制不良游戏&nbsp;拒绝盗版游戏&nbsp;注意自我保护&nbsp;谨防受骗上当&nbsp;适度游戏益&nbsp;沉迷游戏伤身&nbsp;合理安排时间&nbsp;享受健康生活
        </div>
        <div class="text_30">
          <span class="text_cp">
            ©&nbsp;2023&nbsp;KRAFTON,&nbsp;INC.&nbsp;PUBG是KRAFTON,&nbsp;INC.的注册商标或服务标志。
          </span>
<!--          <a href="https://beian.miit.gov.cn/" class="text_beian">沪ICP备2023027933号-1</a>-->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>
<style scoped lang="css" src="./css/footer.css"/>