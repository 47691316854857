<template>
  <div class="page flex-col">
    <div class="image-wrapper_1 flex-col">
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPnge96b2357c88cedbc1eb9ef317a415a4192a837ee4f3935c9cb1dcadbcd6123dc.png"
      />
    </div>
    <div class="box_1 flex-col">
      <span class="text_1">重置密码</span>
      <div class="box_2 flex-col">
        <div class="group_1 flex-col"></div>
        <div class="group_2 flex-col"></div>
        <div class="group_3 flex-col"></div>
      </div>
      <div class="text-wrapper_6 flex-row justify-between">
        <span class="text_2">验证手机号码</span>
        <span class="text_3">设置新密码</span>
        <span class="text_4">密码重置成功</span>
      </div>
      <div class="text-wrapper_2 flex-col"><span class="text_5">请输入手机号</span></div>
      <div class="text-wrapper_3 flex-row justify-between">
        <span class="text_6">请输入短信验证码</span>
        <span class="text_7">获取验证码</span>
      </div>
      <div class="image-text_3 flex-col justify-between">
        <div class="text-wrapper_4 flex-col"><span class="text_8">下一步</span></div>
        <span class="text-group_1">返回账号登录</span>
      </div>
      <div class="box_3 flex-col"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />