<template>
  <!--  <div class="page flex-col justify-end">-->
  <div class="page flex-col">
    <!--          这里是header-->
    <Header></Header>

    <!--    排行榜-->
    <van-col class="goods flex-col">
      <div class="goods_top">
        <van-tabs v-model="goodsActive" @change="handleChange">
          <van-tab v-for="(tab, index) in goodsCate" :key="index" :title="tab.name" :index="index">
            <div class="goods_line"></div>
            <van-row>
              <van-empty v-if="goods.length === 0" description="暂无商品"/>

              <van-col v-for="(item, index) in goods" :key="index" class="list-item">
                <div class="goods_info_list flex-col">
                  <div>
                    <img class="goods_info_list_img"
                         :src=item.image>
                  </div>
                  <div class="goods_info_list_title">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="goods_info_list_price">
                    <img class="money_icon" src="./assets/img/money_icon.png"/>
                    <span>{{ item.price }}</span>
                  </div>
                  <div class="goods_info_list_remain">
                    <span>剩余：{{ item.num }}</span>
                  </div>
                  <van-button class="to_buy" @click="toBuy(item.id)">立即兑换</van-button>
                </div>
              </van-col>
            </van-row>
          </van-tab>
        </van-tabs>
      </div>
    </van-col>
<!--    <van-pagination v-model="current_page" :total-items=total_items :items-per-page=per_page-->
<!--                    :show-page-size="19"-->
<!--                    @change="changePage"-->
<!--                    force-ellipses>-->
<!--      <template #prev-text>-->
<!--        <van-icon name="arrow-left"/>-->
<!--      </template>-->
<!--      <template #next-text>-->
<!--        <van-icon name="arrow"/>-->
<!--      </template>-->
<!--      <template #page="{ text }">{{ text }}</template>-->
<!--    </van-pagination>-->


    <Footer></Footer>


  </div>

</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import {goodsCategory, goodsList} from "../../utils/api/goods"
import {Toast} from "vant";


export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      goodsActive: 0,
      category_id: 1,
      page: 1,
      per_page: 10,
      total_items: 0,
      current_page: 1,
      goodsCate: [],
      goods: [],
    };
  },
  methods: {
    handleChange(index) {
      this.page = 1;
      this.category_id = this.goodsCate[index]['id'];
      this.getGoodsList();
    },
    toBuy(id) {
      this.$router.push('/goods/detail?id=' + id)
    },
    changePage(page) {
      this.page = page;
    },
    getGoodsList() {
      let param = {
        page: this.page,
        per_page: this.per_page,
        category_id: this.category_id,
      };
      goodsList(param).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.goods = res.data.data.data;
          this.total_items = res.data.data.total;
          return
        }
        Toast.fail(res.data.msg)
      })
    },
    getGoodsCategory() {
      goodsCategory({}).then((res) => {
        if (res.data.code === 200) {
          this.goodsCate = res.data.data;
          this.category_id = res.data.data[0].id;
          this.getGoodsList();
        }
      })
    }
  },
  mounted() {
    this.getGoodsCategory();
  }
};
</script>
<style scoped lang="css" src="./assets/index.css"/>