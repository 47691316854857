<template>
  <div class="left">

    <div class="user_info flex-col">
      <div class="user_img">
        <img
            class="image_3"
            referrerpolicy="no-referrer"
            :src=user_info.avatar
        />
      </div>

      <span class="text_7">{{ user_info.fullname }}</span>
      <span class="text_8">ID：{{ user_info.id }}</span>
      <div class="box_10" v-if="user_info.display_icon">
        <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./images/image2.png"
        />
<!--        <img-->
<!--            class="image_5"-->
<!--            referrerpolicy="no-referrer"-->
<!--            src="./images/image3.png"-->
<!--        />-->
      </div>
    </div>
    <div class="gap"></div>
    <div class="data_link">
      <div class="left_me flex-col">
        <van-sidebar v-model="activeKey1">
          <van-sidebar-item v-for="(item,index) in linkGroup1" :title="item.name" :key="index" :url="item.url"/>
        </van-sidebar>
      </div>
      <div class="gap"></div>
      <div class="left_data flex-col">
        <div class="left_me flex-col">
          <van-sidebar v-model="activeKey2">
            <van-sidebar-item v-for="(item,index) in linkGroup2" :title="item.name" :key="index" :url="item.url"/>
          </van-sidebar>
        </div>
        <!--        <router-link target="_blank" target="_blank"class="left_data_text pointer" to="/user/submit">数据提交</router-link>-->
        <!--        <router-link target="_blank" target="_blank"class="left_data_text pointer" to="/user/work_order">工单中心</router-link>-->
      </div>
    </div>
  </div>

</template>

<script>
import {getUserInfo} from "../utils/token"

export default {
  name: "Left",
  data() {
    return {
      user_id: '1',
      user_name: '',
      user_info: {},
      activeKey1: -1,
      activeKey2: -1,
      linkGroup1: [
        {
          'type_id': 1,
          'name': '我的数据',
          'url': '/user/my_data'
        },
        {
          'type_id': 2,
          'name': '基础资料',
          'url': '/user/info'
        },
        {
          'type_id': 3,
          'name': '我的钱包',
          'url': '/user/wallet'
        },
        {
          'type_id': 4,
          'name': '我的订单',
          'url': '/user/order'
        },
      ],
      linkGroup2: [
        {
          'type_id': 1,
          'name': '数据提交',
          'url': '/user/submit'
        },
        {
          'type_id': 2,
          'name': '工单中心',
          'url': '/user/work_order'
        }
      ],
    };
  },
  methods: {
    handleChange() {

    }
  },
  mounted() {
    this.user_info = getUserInfo();
    let that = this
    this.linkGroup1.forEach(function (item) {
      if (that.$router.currentRoute.path.startsWith(item.url)) {
        that.activeKey1 = item.type_id - 1;
      }
    })
    this.linkGroup2.forEach(function (item) {
      if (that.$router.currentRoute.path.startsWith(item.url)) {
        that.activeKey2 = item.type_id - 1;
      }
    })
  }
}
</script>
<style scoped lang="css" src="./css/left.css"/>