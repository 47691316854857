<template>
  <div class="page flex-col">
    <Header></Header>
    <div class="section_2 flex-col">
      <div class="group_6 flex-row justify-between">

        <Left></Left>

        <div class="work_order">

          <div>
            <div class="text_16">提交工单</div>

            <div class="group_7">
              <van-field
                  readonly
                  clickable
                  name="picker"
                  :value="type"
                  label="工单类型"
                  placeholder="请选择工单类型"
                  @click="showPicker = true"
              />
              <van-popup v-model="showPicker" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="orderTypes"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
                />
              </van-popup>
            </div>
          </div>

          <div class="editor_box">
            <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="content"
            >
            </vue-editor>
          </div>


          <div class="image-text_3">
            <span class="text-group_1">*请务必详尽输入工单内容</span>
            <div class="text-wrapper_4 flex-col">
              <button class="text_21" @click="submitOrder">立即提交</button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Left from "../../components/Left";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {submitWorkOrder} from "../../utils/api/user"
import {uploadFile} from "../../utils/api/upload"
import {VueEditor} from "vue2-editor";
import {Toast} from "vant";


export default {
  components: {
    Header,
    Footer,
    Left,
    VueEditor
  },
  data() {
    return {
      type: '',
      showPicker: false,
      constants: {},
      orderTypes: [
        '建议意见',
        '创作者升级',
        '合作者权限',
        '游戏BUG',
        '举报',
      ],
      content: '',
    };
  },
  methods: {
    onConfirm(value) {
      this.type = value;
      this.showPicker = false;
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      console.log(file)
      uploadFile(file).then((res) => {
        Editor.insertEmbed(cursorLocation, "image", res);
        resetUploader();
      })
    },
    submitOrder() {
      if (this.type === '' || this.content === '') {
        Toast.fail('请输入内容');
        return false;
      }
      const formData = new FormData();
      formData.append('type', this.type);
      formData.append('content', this.content);
      submitWorkOrder(formData).then((res) => {
        if (res && res.data.code === 200) {
          Toast.success('提交成功！');
          this.content = '';
          this.type = '';
        }
      })
    },
  },
  mounted() {

  }
}
</script>
<style scoped lang="css" src="./assets/index.css"/>